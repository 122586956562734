import { Table } from '@pascom/react-table-library';
import { centralFetch } from '../utils/central-fetch'
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFileDrop } from '../contexts/FileDropContext';
import { useTranslation } from 'react-i18next';

import { Layout as DashboardLayout } from '../layouts/dashboard/layout';
import { useRef } from 'react';
import '../styles/variables.scss'

import moment from 'moment';
import "moment/dist/locale/cs"
moment.locale("cs");

/**
 * Callback tabulky: Funkce generuje custom buttony do řádků - vrací pole s objekty buttons { title, icon, myKey, onClick }
 *
 * @param {object} row - objekt řádku
 * @param {object} table - instance tabulky
 * @param {string} route - název routy
 * @param {object} history - props
 * @returns {Array} vždy vrací zpět pole objektů, objekt = row button data
 */
function addCustomRowButton(row, table, navigate, route) {
  //console.log('route', route)
  // Pokud jsem v tabulce actual_alerts a řádek ještě není potvrzený tak zobrazím potvrzovací tlačítko
  if (route === 'question/') {
    return [
      {
        title: 'Spravovat otázku',
        icon: 'document-b',//'contract',
        myKey: 'manage-question',
        id: 'manage-question',
        onClick: e => {
          const questionId = row.original.QUESTION_ID;
          navigate(`/edit_question/${questionId}`)
          e.stopPropagation();
        }
      }
    ];
  }

  if (route === 'test_kit/') {
    return [
      {
        title: 'Spravovat testovou sadu',
        icon: 'document-b',//'contract',
        myKey: 'manage-test-kit',
        id: 'manage-test-kit',
        onClick: e => {
          const id = row.original.TEST_KIT_ID;
          navigate(`/edit_test_kit/${id}`)
          e.stopPropagation();
        }
      }
    ];
  }

  return [];
}

const PageTable = ({ func }) => {
  const [tableKey, setTableKey] = useState(1);
  const fileDrop = useFileDrop(`ajax/${func}`, (result) => setTableKey(tableKey + 1))
  const { t } = useTranslation()
  const baseUrl = import.meta.env.VITE_SERVER_URL || "/";
  const navigate = useNavigate();
  const tableTexts = t("react-table-library", { returnObjects: true });


  const tabs = useRef({});

  useEffect(() => {
    //setTableKey(prev => prev + 1);
    //setTableKey(prev => Math.random());
  }, [func])

  useEffect(() => {
    //console.log({ tableKey })
  }, [tableKey])

  function tableButtons(table) {
    let { props, state } = table;
    let buttons = [];

    if (state?.Extended?.Exportable || state?.Exportable) {
      buttons.push({
        title: t('general.export'),
        icon: 'export',
        callback: () => {
          const { sorted = [], filtered = [], page, pageSize } = state;
          let body = {
            sb: sorted?.length !== 0 ? sorted?.[0]?.id : '',
            sd:
              sorted && sorted?.length !== 0
                ? sorted?.[0]?.desc
                  ? 'desc'
                  : 'asc'
                : '',
            si: page === 0 ? 1 : page * pageSize + 1,
            ps: pageSize,
            search: {},
            export: true
          };

          filtered.forEach(val =>
            Object.assign(body?.search, { [val?.id]: encodeURIComponent(val?.value) })
          );
          body.search = JSON.stringify(body.search);
          let params = Object.entries(body)
            .map(entry => entry.join('='))
            .join('&');

          centralFetch({
            method: 'GET',
            param: state.func + '?' + params,
            loader: true,
            download: true
          });
        },
        id: 'EXPORT_BUTTON',
        show: true
      });
    }

    if (state?.Extended?.import) {
      buttons.push({
        title: t(`general.import`),
        icon: 'add',
        callback: (event, table) => fileDrop.open({ path: state?.Extended?.import?.url || `ajax/${func}` }),
        show: true,
      });
    }

    return buttons;
  }


  const tableCallbacks = {
    addCustomRowButton: (row, table) => addCustomRowButton(row, table, navigate, func),
    tableButtons: (table) => tableButtons(table)
  }

  const renderTable = (props, tableProps, Tabl) => {

    //console.log('render ... table ...', props?.tableIndex, { props, tableProps, Tabl, tabs: tabs?.current })

    if (Array.isArray(props?.Extended?.rowclick)) {
      tabs.current[props?.tableIndex] = props?.Extended?.rowclick;
      props.Extended.rowclick = props?.Extended?.rowclick?.[0]?.[1];
    }


    if (props?.tableIndex > 0 && tabs?.current?.[props?.tableIndex - 1])
      return <AlternativeTables tables={tabs?.current?.[props?.tableIndex - 1]} tableProps={tableProps} props={props} Tabl={Tabl} />

    return <Tabl {...tableProps} /* locale={handleStorage('get', 'user')?.PreferedLanguage} */ />
  }


  const renderForm = (props, children) => {
    //console.log('render ... form ...', props.tableIndex, props.extendTable, props)

    setTimeout(() => document.querySelectorAll("#dialog_form_undefined > div > input[type=number]").forEach(input => {
      input.onwheel = (e) => e.currentTarget.blur()
    }), 1000)

    return <div className={'form-wrapper'}>
      {(props?.extend || props?.extendTable) && <h2>{t('general.info')}</h2>}
      {children}
    </div>
  }


  const AlternativeTables = ({ props, tableProps, tables, Tabl }) => {

    const [table, setTable] = useState(0)
    const [title, url] = tables[table];
    let splitUrl = props?.func?.split('/')
    const recID = splitUrl?.[splitUrl.length - 1];

    useEffect(() => console.log({ table }), [table])

    //console.log('alternative tables... ', props?.tableIndex, { props, tableProps, tables, title, url, recID });

    return <>
      <div className='tabletabs'>
        <div>
          {tables?.map((rec, i) => <label
            key={i}
            className={table === i ? `active` : (window?.centralFetchRunning ? `disabled` : ``)}
            onClick={() => {
              if (table !== i && !window.centralFetchRunning) setTable(i)
            }}
          >
            {rec[0]}
          </label>)}
        </div>
      </div>
      <Table
        className={'no_title'}
        //table_key={baseUrl + props?.tableIndex + table + 100/* Math.random() */}
        table_key={tableKey}
        serverUrl={baseUrl + "ajax"}
        centralFetch={centralFetch}
        title={title}
        tableIndex={baseUrl + props?.tableIndex + table + 100}
        func={`${url || 'test'}/${recID}`}
        callbacks={tableCallbacks}
        render={(_props, tableProps, Tabl) => {
          return renderTable(_props, tableProps, Tabl)
        }}
        renderForm={(_props, children) => {
          return renderForm(_props, children)
        }}
        locale={tableTexts}
      />
    </>
  }

  return (
    <Box p={2}>
      <Table
        centralFetch={centralFetch}
        serverUrl={baseUrl + "ajax"}
        func={func}
        //table_key={baseUrl + tableKey}
        table_key={tableKey}
        action={`this.refreshData({type: 'from_storage', func: '${func}' })`}
        render={renderTable}
        renderForm={renderForm}
        callbacks={tableCallbacks}
        locale={tableTexts}
      />
    </Box>
  )
}

PageTable.getLayout = (page) => (
  <DashboardLayout>
    {page}
  </DashboardLayout>
);

export default PageTable;