import { Box, Button, Card, CardActions, CardContent, CardHeader, Container, Divider, Unstable_Grid2 as Grid, LinearProgress, SvgIcon, Table, TableBody, TableCell, TableHead, TableRow, Typography, Select, MenuItem } from '@mui/material';
import { Layout as DashboardLayout } from '@/layouts/dashboard/layout';
import REST from '../utils/rest';
import { useCallback, useEffect, useState } from 'react';
import { toastError, toastSuccess } from '../utils/toasts';
import { useNavigate } from 'react-router-dom';
import DateRangePickerEmbeded from '../components/date-range-picker';
import { useTranslation } from 'react-i18next';
import { ArrowUpTrayIcon } from '@heroicons/react/24/solid';
import SimpleBar from 'simplebar-react';

const DateRange = ({ callback }) => {
  return <DateRangePickerEmbeded callback={callback} />
}

const QuestionsTable = ({ title, tests, tests_groups, tests_users, navigate, t }) => {

  const data = tests || tests_groups || tests_users || []
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'original' })
  const [selectedCategory, setSelectedCategory] = useState('')

  const handleSort = (key) => {
    let direction = 'asc'
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'
    } else if (sortConfig.key === key && sortConfig.direction === 'desc') {
      direction = 'original'
    }
    setSortConfig({ key, direction })
  }

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'asc') return ' ⮝'
      if (sortConfig.direction === 'desc') return ' ⮟'
    }
    return ''
  }

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value)
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const filteredData = selectedCategory
    ? data.filter((item) => item.QUESTION_TYPE === selectedCategory)
    : data

  const sortedData = [...filteredData]
  if (sortConfig.key) {
    sortedData.sort((a, b) => {
      const aValue = a[sortConfig.key]
      const bValue = b[sortConfig.key]

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        if (sortConfig.direction === 'asc') {
          return aValue.localeCompare(bValue, undefined, { sensitivity: 'base' })
        } else if (sortConfig.direction === 'desc') {
          return bValue.localeCompare(aValue, undefined, { sensitivity: 'base' })
        }
      } else if (typeof aValue === 'number' && typeof bValue === 'number') {
        if (sortConfig.direction === 'asc') {
          return aValue - bValue
        } else if (sortConfig.direction === 'desc') {
          return bValue - aValue
        }
      }
      return 0
    })
  }

  let categories = [t("CLOSED"), t("ORDER"), t("RANGE"), t("CLOSEDIMG"), t("DROPDOWN")]

  return (
    <Grid xs={12}>
      <Card sx={{ height: '100%' }}>
        <CardHeader title={title} />
        <SimpleBar>
          <Box sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell onClick={() => handleSort('QUESTION_NAME')} sx={{ cursor: 'pointer' }}>
                    {t(`general.question`)}{getSortIcon('QUESTION_NAME')}
                  </TableCell>
                  <TableCell>
                    <Select
                      value={selectedCategory}
                      onChange={handleCategoryChange}
                      displayEmpty
                      sx={{ cursor: 'pointer' }}
                    >
                      <MenuItem value="">{capitalizeFirstLetter(t(`general.all`))}</MenuItem>
                      {categories.map((category, index) => (
                        <MenuItem key={index} value={category}>{category}</MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell onClick={() => handleSort('TOTAL_DISPLAYED')} sx={{ cursor: 'pointer' }}>
                    {t(`question_evaluation.total_displayed`)}{getSortIcon('TOTAL_DISPLAYED')}
                  </TableCell>
                  <TableCell onClick={() => handleSort('PERCENT_DISPLAYED')} sx={{ cursor: 'pointer' }}>
                    {t(`question_evaluation.percent_displayed`)}{getSortIcon('PERCENT_DISPLAYED')}
                  </TableCell>
                  <TableCell onClick={() => handleSort('TOTAL_CORRECT')} sx={{ cursor: 'pointer' }}>
                    {t(`question_evaluation.total_successful`)}{getSortIcon('TOTAL_CORRECT')}
                  </TableCell>
                  <TableCell onClick={() => handleSort('PERCENT_CORRECT')} sx={{ cursor: 'pointer' }}>
                    {t(`question_evaluation.percent_successful`)}{getSortIcon('PERCENT_CORRECT')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.map((rec, index) =>
                  <TableRow
                    hover
                    key={index}
                  // style={{ cursor: `pointer` }}
                  //Prozatím vypnuté
                  //onClick={() => navigate(`/test/${rec?.ATTEMPT_LAST?.TEST_HISTORY_ID}`)}
                  >
                    <TableCell>
                      {rec.QUESTION_NAME}
                    </TableCell>
                    <TableCell>
                      {rec.QUESTION_TYPE}
                    </TableCell>
                    <TableCell>
                      {rec.TOTAL_DISPLAYED}
                    </TableCell>
                    <TableCell>
                      {rec.PERCENT_DISPLAYED}%
                      <LinearProgress
                        title={`${rec.PERCENT_DISPLAYED}%`}
                        value={rec.PERCENT_DISPLAYED}
                        variant="determinate"
                        thickness={4}
                      />
                    </TableCell>
                    <TableCell>
                      {rec.TOTAL_CORRECT}
                    </TableCell>
                    <TableCell>
                      {rec.PERCENT_CORRECT}%
                      <LinearProgress
                        title={`${rec.PERCENT_CORRECT}%`}
                        value={rec.PERCENT_CORRECT}
                        variant="determinate"
                        thickness={4}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {data?.length === 0 && <Typography align='center' style={{ width: `100%`, padding: 32 }} >{t(`general.no_records`)}</Typography>}
          </Box>
        </SimpleBar>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
        </CardActions>
      </Card>
    </Grid>
  )
}

const Page = () => {

  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState([null, null])
  const [data, setData] = useState(null);
  const { t, i18n } = useTranslation()

  const getData = useCallback(async (export_data) => {
    console.log(`getData`, dateRange, dateRange?.[0] && dateRange?.[1])
    if (dateRange?.[0] && dateRange?.[1]) {
      const params = { from: new Date(dateRange?.[0]).getTime(), to: new Date(dateRange?.[1]).getTime() }
      const queryParams = `?${Object.keys(params)?.map(key => `${key}=${params[key]}`).join(`&`)}`
      console.log({ params, queryParams })
      const [success, newdata] = await REST.GET(`question_evaluation${queryParams}${export_data ? `&export=1` : ``}`, { download: export_data })
      if (!success) toastError(`${t(`general.error`)} ${t(`general.loading_data`)}`)
      if (export_data) return toastSuccess(`${t(`question_evaluation.export`)} ${t(`question_evaluation.successful`)}`)
      setData(newdata)
    }
  }, [dateRange])

  // useEffect(() => {
  //   getData()
  // }, [])

  useEffect(() => {
    console.log(`date range changed`, dateRange)
    getData()
  }, [dateRange])

  // if (!data)
  //   return <Skeleton />

  return <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 4
    }}
  >
    <Container>

      {/* TITLE */}
      <Box display={`flex`} justifyContent={`space-between`} flexWrap={"wrap"} marginBottom={2}>
        <Typography variant="h2" marginBottom={0}>{t(`question_evaluation.title`)}</Typography>
        <Button
          sx={{ fontSize: 32 }}
          onClick={() => getData(true)}
          startIcon={(
            <SvgIcon sx={{ fontSize: 32 }}>
              <ArrowUpTrayIcon sx={{ fontSize: 32 }} />
            </SvgIcon>
          )}>
          {t(`question_evaluation.export`)}
        </Button>
      </Box>

      <Grid container spacing={3} >
        <Grid>
          <DateRange callback={setDateRange} />
        </Grid>
      </Grid>

      <Grid container spacing={3} >
        <QuestionsTable title={`${t(`question_evaluation.title`)}`} tests={data?.questions} navigate={navigate} {...({ t, i18n })} />
      </Grid>

    </Container>
    {/* <Placeholder /> */}
  </Box >
}

Page.getLayout = (page) => (
  <DashboardLayout>
    {page}
  </DashboardLayout>
);

export default Page;
