import React, { useState } from 'react';
import { Box, Button, Typography, TextField } from '@mui/material';
import REST from "../../utils/rest";
import { t } from 'i18next';
import { toastDismiss, toastError, toastLoading, toastSuccess } from "../../utils/toasts";

const Legend = ({ data }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [editData, setEditData] = useState(data);
  const [originalData, setOriginalData] = useState(data);

  const toggleLegend = () => {
    setIsVisible(!isVisible);
  };

  const handleEdit = (id, field, value) => {
    setEditData(editData.map(item => item.id === id ? { ...item, [field]: value } : item));
  };

  const handleKeyPress = async (e, id) => {
    if (e.key === 'Enter') {
      const item = editData.find(item => item.id === id);
      try {
        //Spojíme pomocí ' - '. Ale jen pokud jsou oba stringy neprázdné
        let name = item.number && item.name ? `${item.number} - ${item.name}` : `${item.number}${item.name}`;
        let data = { USER_DEPARTMENT_ID: item.id, USER_DEPARTMENT_NAME: name, MAP_UPDATE: true };
        const response = await REST.PUT('ajax/user_department', { ...data });
        console.log('response', response);
        if (!response[0]) {
          if (response[1]?.Duplicity) {
            throw new Error(response[1]?.feMessage);
          }
          throw new Error(t("errors.500"));
        }
        toastSuccess(t("react-table-library.recordSuccessEdited"), { position: "bottom-right" });
        setOriginalData(editData); // Update original data on success
      } catch (error) {
        console.error('error', error);
        toastError(error.message, { position: "bottom-right" })
        setEditData(originalData); // Restore original data on error
      }
    }
  };

  const handleBlur = (id) => {
    const originalItem = originalData.find(item => item.id === id);
    setEditData(editData.map(item => item.id === id ? originalItem : item));
  };

  return (
    <Box sx={{ position: 'absolute', top: 80, left: 10, zIndex: 10 }}>
      <Button variant="contained" onClick={toggleLegend}>
        {isVisible ? t("map.hide_legend") : t("map.show_legend")}
      </Button>
      {isVisible && (
        <Box sx={{ backgroundColor: 'white', padding: 2, marginTop: 1, borderRadius: 1, boxShadow: 3, maxHeight: 'calc(100vh - 150px)', overflowY: 'auto' }}>
          <table>
            <thead>
              <tr>
                <th>{t("NUMBER")}</th>
                <th>{t("NAME")}</th>
              </tr>
            </thead>
            <tbody>
              {editData.map((item) => (
                <tr key={item.id}>
                  <td>
                    <TextField
                      value={item.number}
                      onChange={(e) => handleEdit(item.id, 'number', e.target.value)}
                      onKeyPress={(e) => handleKeyPress(e, item.id)}
                      onBlur={() => handleBlur(item.id)}
                      size="small"
                      sx={{ width: '100px', height: '30px', display: 'flex', alignItems: 'center' }}
                      inputProps={{ style: { textAlign: 'center', padding: 0, height: '100%' } }}
                    />
                  </td>
                  <td>
                    <TextField
                      value={item.name}
                      onChange={(e) => handleEdit(item.id, 'name', e.target.value)}
                      onKeyPress={(e) => handleKeyPress(e, item.id)}
                      onBlur={() => handleBlur(item.id)}
                      size="small"
                      sx={{ width: '150px', height: '30px', display: 'flex', alignItems: 'center' }}
                      inputProps={{ style: { textAlign: 'center', padding: 0, height: '100%' } }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      )}
    </Box>
  );
};

export default Legend;