import { Box, Button, Card, Stack, SvgIcon, Typography, styled, useTheme } from '@mui/material'
import { Chart } from '@/components/chart';
import { CheckCircleIcon, XCircleIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/solid'
import { useTestContext } from '../../contexts/TestContext';
import { ArrowForwardRounded, Refresh, Logout } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useEnterTestKit from '../../hooks/useEnterTest';
import { logOut } from '../../services/authService';
import { useAuth } from '../../contexts/AuthContext';
import { Fragment, useCallback } from 'react';

const StatusBox = styled(Box, { shouldForwardProp: (prop) => prop !== "success" })(({ theme, success }) => {
  const colorName = success ? "success" : "error";
  return ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1rem",
    background: theme.palette[colorName].main,
    color: theme.palette[colorName].darkest
  })
})

const useChartOptions = () => {
  const theme = useTheme();

  return {
    chart: {
      background: 'transparent',
      dropShadow: {
        enabled: false,
      },
      foreColor: theme.palette.text.primary,
    },
    colors: [theme.palette.success.main, theme.palette.error.main],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          labels: {
            show: false,
          }
        }
      }
    },
    states: {
      active: {
        filter: {
          type: 'none'
        }
      },
      hover: {
        filter: {
          type: 'none'
        }
      }
    },
    stroke: {
      width: 0
    },
    theme: {
      mode: theme.palette.mode
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: function (val) {
          return val + "%"
        },
      }
    }
  };
};

const Finish = () => {
  const { test, progress } = useTestContext();
  const enterTest = useEnterTestKit();
  const { t } = useTranslation();
  const testKitId = test?.test_kit_id;
  //const successAnswers = test.totalQuestions - progress.wrongAnswers;
  //Správně vypočítáme podle aktuální otázky a počtu chyb
  const successAnswers = progress.actualQuestion - progress.wrongAnswers;
  const isSuccess = progress.phase === "finished";
  const chartOptions = useChartOptions(isSuccess);
  const successPercentage = (successAnswers / test.totalQuestions) * 100;
  const chartSeries = [successPercentage, 100 - successPercentage];

  const StatusIcon = isSuccess ? CheckCircleIcon : XCircleIcon;
  const StatusText = isSuccess ? t("question_evaluation.success") + "!" : t("question_evaluation.fail");
  const mainStatusText = t(isSuccess ? "question_evaluation.test_sucessful" : "question_evaluation.test_failed");
  const secondaryText = t(isSuccess ? "question_evaluation.success_message" : "question_evaluation.fail_message");

  //Pokud jsme na desktopu, tak nastavíme padding na 3rem, jinak na 1rem
  const paddingSize = window.innerWidth > 600 ? "1rem" : "0.5rem";
  const symbolSize = window.innerWidth > 600 ? "10rem" : (isSuccess ? "10rem" : "7rem")
  const textSize = window.innerWidth > 600 ? "3rem" : "2rem";
  const finTextSize = window.innerWidth > 600 ? "1.5rem" : "1rem";
  const StatusTextVariant = window.innerWidth > 600 ? "h2" : "h5";

    // Funkce pro odhlášení uživatele
    const handleSignOut = useCallback(async () => {
      console.log('Logging out automatically...');
      const result = await logOut();
      if (result) {
        window.location.href = "/login";
      }
    }, [logOut]);

  const labels = [
    {
      id: "questions",
      color: "warning",
      label: t("question_evaluation.questions"),
      Icon: QuestionMarkCircleIcon,
      value: test.totalQuestions,
    },
    {
      id: "correct",
      color: "success",
      label: t("question_evaluation.correct"),
      Icon: CheckCircleIcon,
      value: successAnswers
    },
    {
      id: "error",
      color: "error",
      label: t("question_evaluation.mistakes"),
      Icon: XCircleIcon,
      value: progress.wrongAnswers
    }
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh", backgroundColor: isSuccess ? "success.special" : "error.special" }}>
      <StatusBox success={isSuccess}>
        <SvgIcon sx={{ fontSize: "3rem", marginX: 2 }}><StatusIcon /></SvgIcon>
        <Typography variant={StatusTextVariant}>{StatusText}</Typography>
      </StatusBox >

      <Box sx={{ display: "flex", justifyContent: "center", p: 0, flexDirection: "column", alignItems: "center" }}>
        <Typography sx={{ p: paddingSize }} variant="h4" textAlign="center" color={isSuccess ? "success.main" : "error.main"}>{mainStatusText}</Typography>
        <Typography sx={{ p: paddingSize }} variant="h6" textAlign="center" fontSize="1.5rem">
          {secondaryText.split('\n').map((item, key) => {
            return <Fragment key={key}>{item}<br /></Fragment>
          })}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", p: 1 }}>
        <Typography sx={{ fontSize: symbolSize, color: "#298a08", lineHeight: "1" }}> {!isSuccess ? <img
          src={"/assets/test_img/cheart.png"}
          alt="Broken heart"
          style={{ height: '120px', width: 'auto' }}
        /> : "★"}</Typography>
      </Box>

      <Typography sx={{ fontSize: textSize, p: 0 }} variant="h4" textAlign="center" color={isSuccess ? "success.main" : "error.main"}>{!isSuccess ? t("question_evaluation.try_again") : t("question_evaluation.congratulations")+"!"}</Typography>

      <Card sx={{ margin: "auto", width: "300px", p: 3 }}>
        <Box position="relative" margin="auto">
          <Chart
            height={230}
            options={{ ...chartOptions, labels: [t("question_evaluation.correct"), t("question_evaluation.mistakes")] }}
            series={chartSeries}
            type="donut"
            width="100%"
          />
          <Typography
            variant="h3"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              letterSpacing: '0.05em',       // Rozestavení písmen
              zIndex: 2,
              color: isSuccess ? "success.darkest" : "error.darkest"
            }}
          >
            {successPercentage.toFixed(0)}%
          </Typography>
        </Box>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          spacing={2}
          sx={{ p: 2 }}
        >
          {labels.map(({ id, color, label, Icon, value }, index) => (
            <Box
              key={id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <SvgIcon color={color} fontSize="large">
                <Icon />
              </SvgIcon>
              <Typography sx={{ my: 0.5 }} variant="h6">{label}</Typography>
              <Typography color="text.secondary" variant="subtitle2">{value}</Typography>
            </Box>
          ))
          }
        </Stack >
      </Card>

      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 2, gap: 3 }}>
        <Button component={Link} to="/" variant="contained" size="large" color="primary" sx={{ fontSize: finTextSize }} endIcon={<ArrowForwardRounded />}>{t("general.homepage")}</Button>
        {testKitId && <Button variant="contained" size="large" color="success" sx={{ fontSize: finTextSize }} endIcon={<Refresh />} onClick={() => enterTest(testKitId)}>{t("general.try_again")}</Button>}
          <Button
            variant="contained"
            size="large"
            color="success"
          sx={{ fontSize: finTextSize }}
            onClick={() => handleSignOut()}
          endIcon={<Logout />}
          >
            {t('general.logout')}
          </Button>


      </Box>
    </Box >
  )
}

export default Finish