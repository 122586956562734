import PropTypes from "prop-types"
import { Add, RemoveCircleOutline } from '@mui/icons-material'
import { Box, Button, Checkbox, IconButton, TextField, Typography } from '@mui/material'
import FormInputText from '../Form/TextField';
import FormCheckbox from '../Form/Checkbox';
import { useFieldArray } from 'react-hook-form';
import { useCallback } from 'react';
import { v4 as uuid } from "uuid";
import { useTranslation } from 'react-i18next';

// const getNewAnswer = () => (
//   { 
//   new_id: `new-answer-${uuid()}`, 
//   text: "", 
//   correct: 0 
// });


const ListAnswer = ({ name, control, errors }) => {
  const { t } = useTranslation()
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: `${name}.answers`,
    keyName: "rhf_id"
  });

  const { append: appendRemoved } = useFieldArray({
    control,
    name: `${name}.removed_answers`,
    keyName: "rhf_id"
  });

  console.log('errors', errors)
  const addItem = useCallback(() => {
    append(  { 
      new_id: `new-answer-${uuid()}`, 
      text: t("question_editor.text"),
      correct: 0 
    });
  }, [append, fields]);

  const removeItem = useCallback((index) => {
    const item = fields[index];

    if (!item.new_id)
      appendRemoved(item);

    remove(index);
  }, [remove, appendRemoved, fields]);

  const updateData = (index, propName, value) => {
    update(index, { ...fields[index], [propName]: value });
  }

  const items = fields.map((answer, index) => {
    const error = errors?.answers?.[index];
    const errorProps = error ? {
      error: true,
      helperText: error?.text?.message
    } : {};
    console.log('answer', answer)
    return (
      <Box key={answer.id || answer.new_id} display="flex" justifyContent="center" alignItems="center" gap={1}>
        <Checkbox
          onChange={(e) => updateData(index, "correct", e.target.checked ? 1 : 0)}
          checked={answer?.correct === 1 ? true : false}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth={true}
          onChange={(e) => updateData(index, "text", e.target.value)}
          defaultValue={answer.text}
          {...errorProps}
        />
        <IconButton title={t("general.remove")} onClick={() => removeItem(index)}>
          <RemoveCircleOutline color="error" />
        </IconButton>
      </Box>
    )
  });

  return (
    <Box>
      {items}
      {errors?.answers?.type === "min" &&
        <Typography align="center" p={2} variant="h5" color="error">
          {t('question_editor.add_at_least_one_answer')}
        </Typography>
      }
      <Box px={6} mt={2}>
        <Button variant="contained" size="large" fullWidth startIcon={<Add />} onClick={addItem}>
          {t('question_editor.add_answer')}
        </Button>
      </Box>
    </Box>
  )
}

ListAnswer.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.any,
  name: PropTypes.string
}

export default ListAnswer