import { useRef, useState } from "react";
import PropTypes from 'prop-types';
// import { Portal } from "./portal";
import { Backdrop, Card, CardActions, Divider, Fab, IconButton, Portal, Typography } from "@mui/material";
import { Icon } from "./icon";
import { usePdf } from '@mikecousins/react-pdf';
import '../styles/media-view.scss'
import { useFullscreen } from "../contexts/FullscreenContext";
import { useTranslation } from "react-i18next";

const Fullscreen = () => {
    const { data, open, close } = useFullscreen()

    if (!data) return null;

    return <Content open={open} close={close} {...data} />
}

const Content = ({ title, img, video, file, close }) => {

    const { t, i18n } = useTranslation()

    const PDFContent = () => {

        const [page, setPage] = useState(1);
        const canvasRef = useRef(null);
        const { pdfDocument } = usePdf({
            file: file,
            page,
            canvasRef,
            onDocumentLoadFail: (x) => console.log(`[PDF] onDocumentLoadFail`, x),
            onPageLoadFail: (x) => console.log(`[PDF] onPageLoadFail`, x),
            onDocumentLoadSuccess: (x) => console.log(`[PDF] onDocumentLoadSuccess`, x),
            onPageLoadSuccess: (x) => console.log(`[PDF] onPageLoadSuccess`, x),
            onPageRenderFail: (x) => console.log(`[PDF] onPageRenderFail`, x),
            onPageRenderSuccess: (x) => console.log(`[PDF] onPageRenderSuccess`, x),
        });

        const onCanvasClick = (e) => {
            e.preventDefault()
            e.stopPropagation()
            console.log(e)
        }

        const turnPage = (e, val) => {
            e.preventDefault()
            e.stopPropagation()
            setPage(val)
        }

        return <>
            {!pdfDocument && <span>{t(`general.loading`)}...</span>}
            {pdfDocument && <canvas onClick={onCanvasClick} className="pdf-canvas" style={{ width: `100%`, height: `unset` }} ref={canvasRef} />}
            <div
                style={{
                    position: `absolute`,
                    bottom: 16,
                    left: `50%`,
                    transform: `translateX(-50%)`,
                }}
            >
                <Fab
                    style={{ margin: 4 }}
                    size="medium"
                    aria-label="left"
                    // color="primary" 
                    disabled={page === 1}
                    onClick={(e) => turnPage(e, page - 1)}
                >
                    <Icon path={`ChevronLeftIcon`} />
                </Fab>
                <Fab
                    style={{ margin: 4 }}
                    size="medium"
                    aria-label="right"
                    // color="primary" 
                    disabled={page === pdfDocument?.numPages}
                    onClick={(e) => turnPage(e, page + 1)}
                >
                    <Icon path={`ChevronRightIcon`} />
                </Fab>
            </div>
        </>
    }

    return     <Portal className={`media-view-portal`}>
        <Backdrop open={true} sx={{ zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
            {img && <img style={{ width: `100vw`, height: `100vh`, objectFit: `contain` }} src={img} alt={title} />}
            {video && <video controls style={{ width: `100%` }} src={video} alt={title} />}
            {file && <PDFContent />}
            <Fab
                onClick={() => close()}
                style={{
                    position: `absolute`,
                    top: 16,
                    right: 16,
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.6)',
                }}
                size="large"
                aria-label="close"
            >
                <Icon path={`XMarkIcon`}  large={true}/>
            </Fab>
        </Backdrop>
    </Portal>
}

Fullscreen.propTypes = {
    title: PropTypes.string,
    img: PropTypes.string,
    video: PropTypes.string,
    file: PropTypes.string,
    googleSheets: PropTypes.bool,
    setFullscreen: PropTypes.func,
};

export default Fullscreen;