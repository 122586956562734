import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Modal, Button, Slide } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { checkIfLogged } from '../services/authService';
import { logOut } from '../services/authService';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { el } from 'date-fns/locale';

//Příprava na .env 
const INACTIVITY_LIMIT = import.meta.env.VITE_INACTIVITY_LIMIT || 10 * 60 * 1000; // 10 minut
const WARNING_LIMIT = import.meta.env.VITE_WARNING_LIMIT || 30 * 1000; // 30 sekund

const InactivityHandler = ({ children }) => {
  const { t, i18n } = useTranslation()
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const [countdown, setCountdown] = useState(WARNING_LIMIT / 1000); // Začneme od 10 sekund
  const logoutTimerRef = useRef(null);
  const warningTimerRef = useRef(null);
  const countdownIntervalRef = useRef(null); // Pro ukládání intervalu
  const { currentUser } = useAuth();
  const location = useLocation();
  const UserRights = localStorage?.user ? JSON.parse(localStorage?.user)?.UserRights : null;

  if ( UserRights?.includes("a") ){
    console.log("Admin - automatic logout disabled")
  }
  else if (location.pathname === '/map' ){
    console.log("Map - automatic logout disabled")
  }
  else {
    console.log("User - automatic logout enabled")
  }

  // Funkce pro odhlášení uživatele
  const handleSignOut = useCallback(async () => {
    console.log('Logging out automatically...');
    const result = await logOut();
    if (result) {
      window.location.href = "/login";
    }
  }, [logOut]);

  // Funkce pro resetování časovačů
  const resetTimers = useCallback(() => {
    if (location.pathname === '/map' || UserRights?.includes("a")) {
      if (logoutTimerRef.current) clearTimeout(logoutTimerRef.current);
      if (warningTimerRef.current) clearTimeout(warningTimerRef.current);
      return;
    }

    // Vyčistit předchozí časovače
    if (logoutTimerRef.current) clearTimeout(logoutTimerRef.current);
    if (warningTimerRef.current) clearTimeout(warningTimerRef.current);

    // Nastavení časovače pro varování
    warningTimerRef.current = setTimeout(() => {
      setIsWarningVisible(true);
      setCountdown(WARNING_LIMIT / 1000); // Resetujeme odpočet na 10 sekund

      // Spustíme odpočet
      countdownIntervalRef.current = setInterval(() => {
        setCountdown(prevCountdown => {
          if (prevCountdown > 1) {
            return prevCountdown - 1;
          } else {
            clearInterval(countdownIntervalRef.current);
            return 0;
          }
        });
      }, 1000);

      // Nastavení časovače pro automatické odhlášení
      logoutTimerRef.current = setTimeout(() => {
        handleSignOut();
      }, WARNING_LIMIT);
    }, INACTIVITY_LIMIT);
  }, [handleSignOut, location.pathname]);

  // Poslouchání na změny aktivity a resetování časovačů
  useEffect(() => {
    if (!currentUser) return;

    const handleActivity = () => {
      if (location.pathname === '/map') return;
      setIsWarningVisible(false);
      clearInterval(countdownIntervalRef.current); // Zastavíme odpočet při aktivitě
      resetTimers();
    };

    // Přidání posluchačů událostí
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    resetTimers();

    return () => {
      // Odebrání posluchačů událostí při odmontování komponenty
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      clearInterval(countdownIntervalRef.current); // Zastavíme odpočet při odmontování komponenty
    };
  }, [resetTimers, currentUser, location.pathname]);

  // Funkce pro zůstat přihlášený
  const handleStayLoggedIn = () => {
    setIsWarningVisible(false);
    clearInterval(countdownIntervalRef.current); // Zastavíme odpočet
    resetTimers();
  };

  if (!currentUser) {
    return <>{children}</>;
  }

  return (
    <>
      {children}
      {location.pathname !== '/map' && (
        <Modal open={isWarningVisible} onClose={handleStayLoggedIn}>
          <Slide direction="up" in={isWarningVisible} mountOnEnter unmountOnExit>
            <Box
              sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                bgcolor: 'background.paper',
                p: 2,
                boxShadow: 3,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                {/* Dynamický odpočet */}
                <p>{t("auth.inactive_logout")} {countdown}{"s"}</p>
              </Box>
              <Button variant="contained" color="primary" onClick={handleStayLoggedIn}>
                {t("auth.stay_logged_in")}
              </Button>
            </Box>
          </Slide>
        </Modal>
      )}
    </>
  );
};

export default InactivityHandler;
