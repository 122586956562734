import React, { useEffect, useReducer, useState, Children, useRef, useCallback, useMemo, useContext, createContext } from 'react';
import "ol/ol.css";
import { fromLonLat } from "ol/proj";
import { Polygon, Point, Circle, MultiLineString } from "ol/geom";
import { getCenter } from "ol/extent";
import { Feature } from 'ol';
// import { shiftKeyOnly, altKeyOnly, never } from "ol/events/condition";

import { RControl, RMap, RLayerImage, RLayerVector, RFeature, ROverlay, RLayerHeatmap, RPopup } from "rlayers";
import { RStyle, RIcon, RFill, RStroke, RText } from "rlayers/style";
import useOnClickOutside from "../../hooks/useOnClickOutside.js"
// import useEventListener from "../../../hooks/custom_event_listener.js"
import useRefresh from "../../hooks/useRefresh.js"

//import Slider from '@mui/material/Slider';
// import Loader from '@material-ui/core/CircularProgress';

import marketIcon from "./icon.jsx"
import MDIcon from './icon.jsx';
import { broadcastEvent } from '../../utils/events.js';
import { GetImageSize, cssVar } from '../functions.js';
import REST from '../../utils/rest.js';
import useEventListener from '../../hooks/useEventListener.js';
import { useTranslation } from 'react-i18next';


const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const MapComponent = (props) => {
  //extractiong props
  let {
    controler,
    imageSource,
    initCoords,
    // layers,
    features,
    geofences,
    draggableFeatures,
    onFeatureDragEnd,
    // children,
  } = props;

  //used for history replay
  // const history_features = useState(null)

  // const used_features = history_features || features;

  //hooks
  const [imageProps, setImage] = useState(null) //size of image the source of the image layer
  const [featuresDraggable, setFeaturesDraggable] = useState(draggableFeatures)

  controler.setDraggable = setFeaturesDraggable;

  const [layers, setLayers] = useReducer((state, { action, layerID, value, show, hide }) => {
    let newstate = { ...state }

    switch (action) {
      case `toggle`:
        newstate[layerID] = {
          ...newstate[layerID],
          visibility: !newstate[layerID].visibility
        }
        // console.log(`layers reducer`, newstate[layerID], { action, layerID, value, state, newstate })
        break;
      case `set`:
        newstate[layerID] = {
          ...newstate[layerID],
          visibility: value
        }
        // console.log(`layers reducer`, newstate[layerID], { action, layerID, value, state, newstate })
        break;

      case `bulk_set`:
        for (let layer of show) {
          newstate[layer] = {
            ...newstate[layer],
            visibility: true
          }
        }
        for (let layer of hide) {
          newstate[layer] = {
            ...newstate[layer],
            visibility: false
          }
        }
        break;

      case `gap`:
        newstate[layerID] = {
          ...newstate[layerID],
          visibility: !newstate[layerID].visibility
        }
        break;

      case `gap_shift`:
        newstate[layerID] = {
          ...newstate[layerID],
          visibility: !newstate[layerID].visibility
        }
        break;

      case `show_history_layers`:
        for (let layer in newstate) {
          newstate[layer] = {
            ...newstate[layer],
            visibility: layer?.history ? true : false
          }
        }
        break;
      case `hide_history_layers`:
        for (let layer in newstate) {
          newstate[layer] = {
            ...newstate[layer],
            visibility: layer?.history ? false : true
          }
        }
        break;
      case `add_feature`:
        layerID = value?.layer;
        if (!newstate[layerID])
          newstate[layerID] = controler.getLayersFromFeatures([value])?.[layerID];
        else
          newstate[layerID] = { ...newstate[layerID], features: [...(newstate[layerID]?.features || []), value] }
        break;
      default:
        break;
    }

    return { ...newstate };
  }, controler.getLayersFromFeatures(features));

  controler.setLayers = setLayers;

  useEventListener(`new_feature`, ({ detail }) => {
    let newfeature = detail;
    setLayers({ action: `add_feature`, value: newfeature })
  })

  /**
   * Gets properties (w,h) of the image source of the image layer 
   */
  const getImageSize = async () => {
    let p = await GetImageSize(imageSource);
    controler.imageProps = p;
    setImage(p)
  }

  //on mount
  useEffect(() => {
    getImageSize()
  }, [])

  //on update of layerVisibility state
  // useEffect(() => {
  //     controler.layerVisibility = layerVisibility; //saving to MapManager
  // }, [controler, layerVisibility])

  // let onPointerMove = useCallback(
  //   (e) => {
  //     console.log(controler.translateCoords(e.coordinate.map(x => Math.floor(x))))
  //   }, []
  // )

  //not rendering until imageProps are specified when using imageSource as map image layer
  if (imageSource && !imageProps) return null;

  let imgExtent; // area covered by image in map coords
  let mapExtent; // area viewable on map
  let center; // initial center of the view area

  if (imageProps) {
    imgExtent = [0, 0, imageProps?.w, imageProps?.h];
    mapExtent = [0 - (imageProps?.w / 2), 0 - (imageProps?.h / 2), imageProps?.w + (imageProps?.w / 2), imageProps?.h + (imageProps?.h / 2)]//imgExtent;
    center = getCenter(mapExtent)
  } else {
    center = fromLonLat(initCoords || [0, 0]);
  }


  const generalProps = {
    onFeatureDragEnd,
    draggableFeatures
  }


  return <>
    <RMap
      width={"100%"}
      height={"100%"}
      initial={{ center: center, zoom: 0, showFullExtent: true }}
      extent={mapExtent}
    // onPointerMove={onPointerMove}
    >
      <RControl.RZoom delta={0.3} />
      {imageSource && <RLayerImage
        url={imageSource}
        extent={imgExtent}
      />}
      {Object.values(layers)?.map((layer, lid) => <Layer key={lid} index={lid} controler={controler} show={layers?.[layer?.layerID]?.visibility} {...layer} {...generalProps} />)}
      {/* {Children.map(Children.toArray(children), (child, index) => React.cloneElement(child, { index: layers?.length + index }))} */}
      {/* <RLayerVector>
                <RInteraction.RDraw
                    type={"Polygon"}
                    condition={shiftKeyOnly}
                    freehandCondition={never}
                />

                <RInteraction.RDraw
                    type={"Circle"}
                    condition={altKeyOnly}
                    freehandCondition={never}
                />
            </RLayerVector> */}
    </RMap>
    <LayerControl controler={controler} layers={layers} setLayers={setLayers} {...generalProps} />
    {/* <HistoryControl controler={controler} layers={layers} setLayers={setLayers} {...callbacks} /> */}
  </>
}




/**
 * Component for showing/hiding layers
 * 
 * 
 * @param {*} props 
 * @returns 
 */
const LayerControl = ({ controler, layers, setLayers }) => {

  const layer_control_ref = useRef()
  const [expanded, setExpanded] = useState(false);
  const { t, i18n } = useTranslation()


  console.log(`LAYERS`, layers)

  const toggle = (state) => expanded !== state && setExpanded(!expanded)

  const layerToggle = (e, id) => {
    e.stopPropagation();
    setLayers({ action: `toggle`, layerID: id })
  }

  useOnClickOutside(layer_control_ref, () => {
    toggle(false)
  });

  return <>
    {/* <MDIcon className={`layer-control-icon`} path={`mdiLayersOutline`} onClick={() => toggle(!expanded)} /> */}
    {expanded && <main ref={layer_control_ref} className='layer-control'>
      <header>
        <h2>{t(`map.layers`)}</h2>
      </header>
      <section>
        {Object.values(layers)?.map((layer, lid) => {
          let layerID = layer?.layerID;
          return <label key={lid}>
            <input type="checkbox" checked={layers?.[layerID]?.visibility} onChange={(e) => layerToggle(e, layerID)} />
            {layer?.name}
          </label>
        })}
      </section>
      <footer></footer>
    </main>}
    {expanded && <div className='layer-control-backdrop' />}
  </>
}



/**
 * Component for replaying history of points on the map
 * 
 * 
 * @param {*} props 
 * @returns 
 */
/* const HistoryControl = ({ controler, layers, setLayers }) => {

  const history_control_ref = useRef()
  const [expanded, setExpanded] = useState(false);
  const [marks, setMarks] = useState()

  // const toggle = (state) => expanded !== state && setExpanded(!expanded)

  const play = async (e) => {
    e.stopPropagation();
    setLayers({ action: `show_history_layers` })
    let replay_hist = await controler.getReplayHistory()
    console.log({ replay_hist })
    let marks = {}
    for (let rec of replay_hist) {
      if (rec?.wait) continue;
      let ts = rec.lastChangeTimestamp
      if (ts)
        marks[ts] = ({
          value: new Date(ts).getTime(),
          label: new Date(ts).toLocaleTimeString()
        })
    }
    marks = Object.values(marks)
    marks = marks.sort((a, b) => a.value - b.value)
    console.log({ marks })
    setMarks(marks)
    await controler.replayHistory()
  }

  // useOnClickOutside(history_control_ref, () => {
  //   toggle(false)
  // });

  function valuetext(value) {
    return new Date(value).toLocaleTimeString()
  }

  return <>
    <MDIcon className={`history-control-icon`} path={`mdiHistory`} onClick={(e) => play(e)} />
    {marks && <div className='history-slider-box'>
      <Slider
        className='history-slider'
        aria-label="Restricted values"
        defaultValue={marks?.[0]?.value}
        getAriaValueText={valuetext}
        step={null}
        min={marks?.[0]?.value}
        max={marks?.[marks?.length - 1]?.value}
        valueLabelDisplay="auto"
        marks={marks}
      />
    </div>}
  </>
} */





/**
 * Map layer component 
 * Renders features like markers and areas
 * 
 * 
 * @param {*} props 
 * @returns 
 */
const Layer = (props) => {
  //extractiong props
  const { controler, index, layerID, name, zIndex = 1, show, features, children, heatmap, onFeatureDragEnd, draggableFeatures, ...restprops } = props;

  const layerindex = index;
  const showing = show;//controler.layers?.[layerID]?.visibility
  const [coef, setCoef] = useState(0)
  const toggleCoef = () => setCoef(coef === 0 ? (features?.length * 2) : 0);

  //saving to MapManager
  controler.layers[layerID || index] = { layerID, name, index, zIndex, ...restprops, }

  useEffect(() => {
    // console.log(`features changed`, features)
    toggleCoef()
  }, [features])

  if (!showing) return null;

  if (heatmap)
    return <HeatmapLayer showing={showing} {...props} />

  controler.featuresToRender += features?.length;

  return <RLayerVector zIndex={zIndex}>
    {features?.map((child, chid) => {
      switch (child?.type) {
        case `polygon`:
          return <PolygonComponent layerindex={layerindex} index={chid} controler={controler} key={chid + coef} {...child} />
        case `circle`:
          return <CircleComponent layerindex={layerindex} index={chid} controler={controler} key={chid + coef} {...child} />
        case `indicator`:
          return <IndicatorComponent layerindex={layerindex} index={chid} controler={controler} key={chid + coef} onFeatureDragEnd={onFeatureDragEnd} draggableFeatures={draggableFeatures} {...child} />
        case `point`:
        default:
          return <MarkerComponent layerindex={layerindex} index={chid} controler={controler} key={chid + coef} onFeatureDragEnd={onFeatureDragEnd} draggableFeatures={draggableFeatures} {...child} />
      }
    })}
    {Children.map(Children.toArray(children), (child, index) => React.cloneElement(child, { index, layerindex }))}
  </RLayerVector>
}



/**
 * Heatmap layer component
 * 
 * 
 * @param {*} props 
 * @returns 
 */
const HeatmapLayer = (props) => {
  const { controler, index, layerID, name, zIndex = 1, show, children, showing, ...restprops } = props;

  // const refresh = useRefresh()
  const [features, setFeatures] = useState(controler.getHeatmapFeatures(layerID))

  // const [features, setFeatures] = useState(null)

  useEffect(() => {
    setFeatures(null)
  }, [showing])

  useEffect(() => {
    if (!features && showing)
      setFeatures(controler.getHeatmapFeatures(layerID))
  }, [features])

  useEventListener(`feature_changed`, ({ detail }) => {
    // if (detail === layerID)
    // console.log(`heatmap feature changed`, detail, layerID, features)
    // refresh()
    setFeatures(null)
  })

  // let features = controler.getHeatmapFeatures(layerID)

  if (!features) return null;

  return <RLayerHeatmap
    blur={100}
    radius={64}
    weight={() => 1}
    features={features}
  />
}



/**
 * Component representing marker on the map
 * 
 * 
 * @param {*} props 
 * @returns 
 */
const MarkerComponent = (props) => {
  //extraction props
  let { layerindex, id, index, controler, type } = props;

  //getting proper feature ID
  let featureID = id || props?.featureID || controler.featureID(layerindex, index, type);

  //saving to MapManager
  let featureProps = controler.saveFeature({ ...props, featureID })
  controler.featureRender()

  const ref = useRef();

  //hooks
  // const refresh = useRefresh()
  const [data, setData] = useState({ ...featureProps })
  const [popup, setPopup] = useState(0)

  const togglePopup = (state) => {
    if (popup === 2) return;
    if (popup !== state) {
      setPopup(state)
      // if (state === 1) return setPopup(state)
      // clearToggleTimeout()
      // ref.current.timeout = setTimeout(() => setPopup(state), 1000)
    }
  }

  const clearToggleTimeout = () => {
    clearTimeout(ref.current.timeout)
    ref.current.timeout = null;
  }

  const togglePermanentPopup = () => setPopup(popup === 2 ? 0 : 2)

  useEffect(() => {
    // console.log("feature useEffect", featureID, props)
    setData(() => {
      return {
        ...controler.features?.[featureID],
        lastPositions: controler.history.lastPositions?.[featureID],
        currentAreas: controler.history.lastAreas?.[featureID],
        areasLeft: controler.getPointLeftAreas(featureID),
        areasEntered: controler.getPointEnteredAreas(featureID),
      }
    })
  }, [])

  // const refresh = useRefresh()
  useEventListener(`feature_changed`, ({ detail }) => {
    if (detail === featureID) {
      // console.log(`feature_changed`, featureID, detail, `old`, controler.features?.[featureID]?.coords, `new`, data)

      setData(() => {
        return {
          ...controler.features?.[featureID],
          lastPositions: controler.recordLastPosition(featureID),
          currentAreas: controler.recordLastArea(featureID),
          areasLeft: controler.getPointLeftAreas(featureID),
          areasEntered: controler.getPointEnteredAreas(featureID),
        }
      })
    }
  })


  useEventListener(`features_changed`, ({ detail }) => {
    console.log(`features_changed received`)
    if (controler.features?.[featureID]) {
      setData({
        ...controler.features?.[featureID],
        lastPositions: controler.recordLastPosition(featureID),
        currentAreas: controler.recordLastArea(featureID),
        areasLeft: controler.getPointLeftAreas(featureID),
        areasEntered: controler.getPointEnteredAreas(featureID),
      })
    } else {
      setData(null)
    }
  })

  // useOnClickOutside(ref, () => {
  //     setPopup(0)
  // })

  // console.log("feature render", featureID)

  if (!data) return null;

  let areaDisplayProps = useMemo(() => controler.features?.[data?.currentAreas?.[data?.currentAreas?.length - 1]]?.actions?.displayTransponders || null, [data]);

  let markerSource = (areaDisplayProps?.marker || data?.marker) ? `${REST.URL(areaDisplayProps?.marker || data?.marker)}` : marketIcon;
  let color = cssVar(areaDisplayProps?.color || data.color || `--red`);
  let width = areaDisplayProps?.width || data?.width || 18;
  let anchor = areaDisplayProps?.anchor || data?.anchor || [0.5, 1];

  let showPopup = popup > 0;

  //not showing the marker if it is invisible
  if (data?.invisible) return null;

  // console.log(`MARKER...`, { data, areaDisplayProps, markerSource, color, width, anchor, showPopup })

  return <>
    <RFeature
      ref={ref}
      geometry={new Point(controler.translateCoords(data.coords))}
      onPointerEnter={() => togglePopup(1)}
      onPointerLeave={() => togglePopup(0)}
      onClick={() => togglePermanentPopup()}
    >
      <RStyle>
        {markerSource && <RIcon crossOrigin={markerSource} src={markerSource} color={color} anchor={anchor} width={width} />}
      </RStyle>
      <ROverlay>
        {showPopup && <PopupComponent togglePopup={togglePopup} togglePermanentPopup={togglePopup} clearToggleTimeout={clearToggleTimeout} controler={controler} {...data} />}
      </ROverlay>
    </RFeature>
    {showPopup && <PreviousPath controler={controler} {...data} />}
  </>
}




/**
 * Popup component showing on marker hover
 * 
 * 
 * @param {*} param0 
 * @returns 
 */
const PopupComponent = (props) => {
  const { featureID, controler, name, coords, coordsDefault, currentAreas, areasLeft, areasEntered, togglePopup, togglePermanentPopup, clearToggleTimeout } = props;

  const { t, i18n } = useTranslation()

  return <main
    className='marker-popup'
  // onPointerEnter={() => togglePopup(1)}
  // onPointerLeave={() => togglePopup(0)}
  // onClick={() => togglePermanentPopup()}
  >
    <header><h2>{name || featureID}</h2></header>
    <section>
      <div>
        <label>{t(`map.coords`)}</label>
        <label>{`[${coordsDefault || coords}]`}</label>
      </div>
      {/* {currentAreas?.length > 0 && <div>
        <label>Oblasti</label>
        <label>{currentAreas?.map((x, id) => x).join(`, `)}</label>
      </div>}
      {(areasLeft?.length > 0 || areasEntered?.length > 0) && <div>
        <label>Poslední interakce</label>
        <label>{`[${areasLeft || ""}] -> [${areasEntered || ""}]`}</label>
      </div>} */}
    </section>
    <footer></footer>
  </main >
}



/**
 * Shows previous path of given point on the map
 * 
 * 
 * 
 * @param {*} param0 
 * @returns 
 */
const PreviousPath = ({ controler, lastPositions = controler.history.lastPositions[featureID], featureID }) => {

  let drawable = lastPositions?.map(([x, y]) => [x, y])

  let geometry = new MultiLineString([controler.translateCoords(drawable)]);

  return <>
    <RFeature geometry={geometry}>
      <RStyle>
        <RStroke color={cssVar("--green")} width={2} />
      </RStyle>
    </RFeature>
    {lastPositions?.map((point, pointid) => {
      let coords = [point?.[0], point?.[1]]
      let timestamp = point?.[2]
      return <RFeature key={pointid} geometry={new Point(controler.translateCoords(coords))} >
        {/* <RStyle>
                    <RStroke color={"green"} width={2} />
                </RStyle> */}
        {timestamp && <ROverlay>
          <label className='timestamp_popup'>{new Date(timestamp).toLocaleTimeString()}</label>
        </ROverlay>}
      </RFeature>
    })}
  </>
}





/**
 * Draws polygonal area on the map
 * 
 * 
 * 
 * @param {*} props 
 * @returns 
 */
const PolygonComponent = (props) => {
  //extraction props
  let { layerindex, id, index, controler, border, borderColor, fillColor = `transparent`, coords, type } = props;
  let featureID = id || controler.featureID(layerindex, index, type);

  //saving to MapManager
  controler.saveFeature({ ...props, featureID })
  controler.featureRender()

  const refresh = useRefresh()
  useEventListener(`feature_changed`, ({ detail }) => detail === featureID && refresh())

  return <RFeature geometry={new Polygon([controler.translateCoords(coords)])}>
    <RStyle>
      {border && <RStroke color={cssVar(borderColor)} width={border} />}
      <RFill color={cssVar(fillColor)} />
    </RStyle>
  </RFeature>
}




/**
 * Draws circle area on the map
 * 
 * 
 * 
 * @param {*} props 
 * @returns 
 */
const CircleComponent = (props) => {
  //extracting props
  let {
    layerindex,
    id,
    index,
    controler,
    border,
    borderColor,
    fillColor = `transparent`,
    coords,
    radius,
    type
  } = props;
  let featureID = id || controler.featureID(layerindex, index, type);

  //saving to MapManager
  controler.saveFeature({ ...props, featureID })
  controler.featureRender()

  const refresh = useRefresh()
  useEventListener(`feature_changed`, ({ detail }) => detail === featureID && refresh())

  return <RFeature geometry={new Circle(controler.translateCoords(coords), radius)}>
    <RStyle>
      {border && <RStroke color={cssVar(borderColor)} width={border} />}
      <RFill color={cssVar(fillColor)} />
    </RStyle>
  </RFeature>
}

/**
 * Draws circle area on the map
 * 
 * 
 * 
 * @param {*} props 
 * @returns 
 */
const IndicatorComponent = (props) => {
  //extracting props
  let {
    layerindex,
    id,
    index,
    controler,
    color,
    border = 4,
    coords,
    radius = 300,
    value,
    type,
    onFeatureDragEnd,
    draggableFeatures
  } = props;

  let featureID = id || controler.featureID(layerindex, index, type);

  //saving to MapManager
  let featureProps = controler.saveFeature({ ...props, featureID })
  controler.featureRender()

  const [data, setData] = useState({ ...featureProps })
  const ref = useRef();

  useEffect(() => {
    // console.log("feature useEffect", featureID, props)
    setData(() => {
      return {
        ...controler.features?.[featureID],
        lastPositions: controler.history.lastPositions?.[featureID],
        currentAreas: controler.history.lastAreas?.[featureID],
        areasLeft: controler.getPointLeftAreas(featureID),
        areasEntered: controler.getPointEnteredAreas(featureID),
      }
    })
  }, [])

  // const refresh = useRefresh()
  useEventListener(`feature_changed`, ({ detail }) => {
    if (detail === featureID) {
      // console.log(`feature_changed`, featureID, detail, `old`, controler.features?.[featureID]?.coords, `new`, data)

      setData(() => {
        return {
          ...controler.features?.[featureID],
          lastPositions: controler.recordLastPosition(featureID),
          currentAreas: controler.recordLastArea(featureID),
          areasLeft: controler.getPointLeftAreas(featureID),
          areasEntered: controler.getPointEnteredAreas(featureID),
        }
      })
    }
  })

  useEventListener(`features_changed`, ({ detail }) => {
    console.log(`features_changed received`)
    if (controler.features?.[featureID]) {
      setData({
        ...controler.features?.[featureID],
        lastPositions: controler.recordLastPosition(featureID),
        currentAreas: controler.recordLastArea(featureID),
        areasLeft: controler.getPointLeftAreas(featureID),
        areasEntered: controler.getPointEnteredAreas(featureID),
      })
    } else {
      setData(null)
    }
  })


  useEventListener(`set_draggable`, ({ detail }) => {
    if (controler.features?.[featureID]) {
      setData({
        ...controler.features?.[featureID],
        lastPositions: controler.recordLastPosition(featureID),
        currentAreas: controler.recordLastArea(featureID),
        areasLeft: controler.getPointLeftAreas(featureID),
        areasEntered: controler.getPointEnteredAreas(featureID),
        draggable: detail
      })
    } else {
      setData(null)
    }
  })



  if (!data) return null

  const radiusValue = data?.radius || 200
  const borderValue = data?.border || 4

  const drag_callbacks = {
    onPointerDrag: useCallback((e) => {
      const coords = e.map.getCoordinateFromPixel(e.pixel);
      e.target.setGeometry(new Circle(controler.translateCoords(coords), radiusValue));
      ref.current.dragging = true;
      e?.stopPropagation();
      e?.disablePropagation();
      return false;
    }, [data]),
    onPointerDragEnd: useCallback((e) => {
      const coords = e.map.getCoordinateFromPixel(e.pixel);
      if (ref.current.dragging) {
        controler.featureChanged({ ...props, featureID, coords })
        if (onFeatureDragEnd) onFeatureDragEnd({ ...props, featureID, coords })
        ref.current.dragging = false;
      }
    }, [data])
  }

  return <RFeature
    ref={ref}
    geometry={new Circle(controler.translateCoords(data.coords), radiusValue)}
    {...(draggableFeatures ? { ...drag_callbacks } : {})}
  >
    <RStyle>
      {border && <RStroke color={cssVar(data.color)} width={borderValue} />}
      <RFill color={cssVar(data?.color)} />
      <RText text={`${data?.value}`} scale={1.5} />
      {/* <ROverlay positioning={"center-center"}>
        <label>{data?.value}</label>
      </ROverlay> */}
      {draggableFeatures ? null : <RPopup trigger='hover'>
        <main className='marker-popup'>
          <header><h2>{data?.title}</h2></header>
        </main >
      </RPopup>}
    </RStyle>
  </RFeature >
}


/**
 * Class providing background infrastructure for map component
 */
export default class MapManager {

  /**
   * Konstruktor třídy map manager. Nastaví hlavní parametry
   * @returns instance MapManager
   */
  constructor(props) {
    this.RECORD_LAST_AREAS = props.RECORD_LAST_AREAS;
    this.RECORD_LAST_POSITIONS = props.RECORD_LAST_POSITIONS;
    this.RECORD_FULL_HISTORY = props.RECORD_FULL_HISTORY;
    this.HISTORY_SIZE = props.HISTORY_SIZE || 300;
    this.HEATMAP = props.HEATMAP || false;
    this.HEATMAP_FOR_EACH_LAYER = props.HEATMAP_FOR_EACH_LAYER || false;

    this.imageProps = null;
    this.layers = {};
    // this.layerVisibility = null;
    this.features = {};
    this.featuresToRender = 0;
    this.featuresRendered = 0;


    this.history = {
      lastAreas: {}, //last positions of points (areas in which points were contained after last render)
      pointsLeftAreas: {},
      pointsEnteredAreas: {},
      lastPositions: {},
    }
    // this.lastPositions = {}; //last positions of points (areas in which points were contained after last render)
    // this.pointsLeftAreas = {}
    // this.pointsEnteredAreas = {}
    // this.HISTORY_SIZE = 10;
    return this;
  }

  /**
   * @returns pole bodů na mapě napříč všemi vrstvami
   */
  getPoints() {
    return Object.values(this.features).filter(x => x.type === `point`)
  }

  /**
   * @returns pole oblastí na mapě napříč všemi vrstvami 
   */
  getAreas() {
    return Object.values(this.features).filter(x => x.type === `polygon` || x.type === `circle`)
  }

  getLayersFromFeatures(features = []) {
    let layers = {};
    let heatmap_layers = {};
    for (let feature of features) {
      let layer_id = feature?.layer || `Layer`
      if (!layers[layer_id])
        layers[layer_id] = {
          layerID: feature?.layer,
          name: feature?.layer,
          visibility: true,
          history: feature?.history,
          features: []
        }
      layers[layer_id].features.push(feature)

      //pushing to heatmap layers
      if (feature?.heatmap && this.HEATMAP) {
        let layer_id = this.HEATMAP_FOR_EACH_LAYER ? feature.layer : `Heatmap`;
        // console.log(`HEATMAP`, this.HEATMAP, layer_id, feature)
        if (!heatmap_layers[layer_id])
          heatmap_layers[layer_id] = {
            layerID: layer_id,
            name: layer_id,
            visibility: false,
            history: false,
            features: [],
            heatmap: true
          }
        heatmap_layers[layer_id].features.push(feature)
      }

    }
    // console.log(`getLayersFromFeatures`, layers)
    return { ...layers, ...heatmap_layers };
  }


  getHeatmapFeatures() {
    let features = this.getPoints();
    console.log(`HEATMAP COORDS`, features?.map(f => f.coords.join(``)).join(``))
    if (!features) return null;
    return features.map(f => new Feature({
      geometry: new Point(this.translateCoords(f.coords)),
      weight: 1
    }))
  }



  /**
   * Returns areas left by given point in last step
   * 
   * @param {*} featureID 
   * @returns 
   */
  getPointLeftAreas(featureID) {
    return this.history.pointsLeftAreas?.[featureID]
  }

  /**
   * Returns areas entered by given point in last step
   * 
   * @param {*} featureID 
   * @returns 
   */
  getPointEnteredAreas(featureID) {
    return this.history.pointsEnteredAreas?.[featureID]
  }

  getPointLastArea(featureID) {
    let areas = this.history.pointsEnteredAreas?.[featureID]
    return areas?.[areas?.length - 1] || null;
  }

  /**
   * @param {[x,y] || [[x,y],[x,y], ...]} coords souřadnice
   * @returns převede souřadnice do prostoru začínajícího v levém horním rohu
   */
  translateCoords(coords) {
    if (Array.isArray(coords?.[0]))
      return coords.map(c => this.translateCoords(c))
    if (coords?.length !== 2)
      return null;
    if (this.imageProps)
      return [coords?.[0], /* this.imageProps.h - */ coords?.[1]] //[x,Y]
    return fromLonLat(coords) //[coords?.[0], this.imageProps.h - coords?.[1]]
  }

  /**
   * Creates ID of feature
   * 
   * @param {*} layerindex 
   * @param {*} featureindex 
   * @param {*} type 
   * @returns 
   */
  featureID(layerindex, featureindex, type) {
    return `l${layerindex}${type?.substring(0, 1) || `f`}${featureindex}`
  }

  /**
   * Goes through all points and check if the point is in any areas
   * 
   * @returns array of area featureIDs
   */
  pointsInAreas() {
    let output = {}
    let points = this.getPoints()
    let areas = this.getAreas()
    for (let p of points) {
      for (let a of areas) {
        let intersects = this.pointInArea(p, a)
        if (intersects) {
          if (!output[p.featureID])
            output[p.featureID] = []
          output[p.featureID].push(a.featureID)
        }
      }
    }
    // console.log(`POINTS IN AREAS`, output)
    return output;
  }

  /**
   * Checks if a given point is in any areas
   * 
   * 
   * 
   * @param {*} p point params (record of this.features) 
   * @returns array of area featureIDs
   */
  pointInAreas(p) {
    let areas = this.getAreas()
    let inAreas = [];
    for (let a of areas) {
      let intersects = this.pointInArea(p, a)
      if (intersects) {
        // console.log(`Point ${p.featureID} in ${a.type} area ${a.featureID}`, p, a)
        inAreas.push(a?.featureID)
      }
    }

    return inAreas;
  }

  /**
   * Checks if a given point is in given area
   * 
   * @param {*} point point params (record of this.features) 
   * @param {*} areaShape area params (record of this.features) 
   * @returns boolean
   */
  pointInArea(point, areaShape) {
    if (areaShape.type === `polygon`)
      return this.pointInPolygon(point.coords, areaShape.coords)
    else if (areaShape.type === `circle`)
      return this.pointInCircle(point.coords, areaShape.coords, areaShape.radius)
    return false;
  }

  /**
   * Checks if a given point is in given polygon area
   * 
   * @param {*} point point coords [x,y]
   * @param {*} polygon area coords [ [x,y], [x,y], ... ]
   * @returns boolean
   */

  pointInPolygon(point, polygon) {
    // This function uses the ray-casting algorithm to determine if the point is inside the polygon
    // based on the number of times a horizontal ray extending from the point intersects with the polygon's edges.

    // Initialize the inside flag and the number of intersections
    let inside = false;
    let intersections = 0;

    // Loop through each edge of the polygon
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const xi = polygon[i][0], yi = polygon[i][1];
      const xj = polygon[j][0], yj = polygon[j][1];

      // Check if the edge intersects the horizontal ray extending from the point
      if ((yi > point[1]) !== (yj > point[1]) && point[0] < (xj - xi) * (point[1] - yi) / (yj - yi) + xi) {
        intersections++;
      }

      // Flip the inside flag if the edge passes through the point
      if ((xi === point[0] && yi === point[1]) || (xj === point[0] && yj === point[1])) {
        inside = !inside;
      }
    }

    // The point is inside the polygon if the number of intersections is odd
    return intersections % 2 === 1 || inside;
  }

  /**
   * Checks if a given point is in given circle area
   * 
   * 
   * @param {*} point point coords [x,y]
   * @param {*} circleCenter coords [x,y]
   * @param {*} radius int
   * @returns boolen
   */
  pointInCircle(point, circleCenter, radius) {
    // This function determines if the point is inside the circle by computing the distance between the point and the center of the circle
    const distance = Math.sqrt((point[0] - circleCenter[0]) ** 2 + (point[1] - circleCenter[1]) ** 2);
    return distance <= radius;
  }


  /**
   * Uloží parametry feature do this.features 
   * 
   * @param {*} feature 
   */
  saveFeature(feature) {
    let featureID = feature.featureID;
    // console.log(`saved`, featureID, feature)
    delete feature.controler;
    delete this.features[featureID];
    this.features[featureID] = { ...feature, lastChangeTimestamp: new Date() };
    return this.features[featureID];
  }




  /**
   * Changes params of one specific feature
   * 
   * 
   * @param {*} feature 
   * @returns 
   */
  featureChanged(feature) {
    // console.log(`feature changed`, feature)
    if (!feature?.featureID)
      feature.featureID = feature?.id;
    let featureID = feature?.id || feature?.featureID;
    if (!featureID) return;

    if (!this.features?.[featureID]) {
      let newfeature = this.saveFeature(feature)
      broadcastEvent(`new_feature`, newfeature)
      return newfeature;
    }

    let updatedFeature = this.saveFeature(feature)
    console.log(`feature changed coords`, updatedFeature?.coords)
    broadcastEvent(`feature_changed`, featureID)
    return updatedFeature;
  }


  /**
   * Refreshes all features on the map
   * 
   * 
   */
  refreshAllFeatures(fs) {
    console.log(`refreshing all features`, fs)
    if (!fs) return;
    this.features = fs;
    for (let featureID in this.features) {
      this.featureChanged(this.features[featureID])
    }
    broadcastEvent(`features_changed`, true)
  }



  /**
   * Refreshes all features on the map
   * 
   * 
   */
  setDraggable(val) {
    broadcastEvent(`set_draggable`, val)
  }




  /**
   * Gets replayable histry of all points
   * 
   * 
   */
  async getReplayHistory() {

    if (!this.replay_position_history) {
      let points = this.getPoints()
      let poshist = []

      //create array of all past positions of all points
      for (let point of points) {

        if (point?.history) continue;

        for (let pos of this.history.lastPositions[point.featureID]) {

          //calculate time passed from previous poshist record

          poshist.push({
            ...point,
            layer: `${point.layer} hist.`,
            featureID: `${point.featureID}_hist`,
            id: `${point.id}_hist`,
            coords: [pos?.[0], pos?.[1]],
            lastChangeTimestamp: pos?.[2],
            color: `blue`,
            history: true
          })
        }
      }

      if (poshist.length === 0) return;

      //sort by timestamp
      poshist = poshist.sort((a, b) => a.lastChangeTimestamp - b.lastChangeTimestamp)

      let position_history = []

      // fill in wait times between positions
      for (let index in poshist) {
        let pos = poshist[index]
        let prev_pos_ts = poshist?.[index - 1]?.lastChangeTimestamp
        let this_pos_ts = pos?.lastChangeTimestamp

        let time_passed_ms = this_pos_ts - prev_pos_ts

        if (time_passed_ms < 100)
          time_passed_ms = 100
        else if (time_passed_ms > 300)
          time_passed_ms = 300

        position_history.push({ wait: time_passed_ms })
        position_history.push(pos)
      }

      console.log({ position_history })

      this.replay_position_history = position_history

    }
    return this.replay_position_history;
  }


  /**
   * Replaying recent lastPositions history on the map
   * 
   * 
   * 
   * @returns
   * @memberof MapManager
  */
  async replayHistory() {
    if (!this.replay_position_history) return;
    console.log(`REPLAYING HISTORY (${this.replay_position_history.length} steps)`)
    for (let index in this.replay_position_history) {
      let record = this.replay_position_history[index]
      if (record.wait) {
        console.log(`${parseInt(index) + 1}/${this.replay_position_history.length} - WAITING ${record.wait}ms`)
        await wait(record.wait)
      } else {
        console.log(`${parseInt(index) + 1}/${this.replay_position_history.length} - REPLAYING ${record.featureID}`)
        this.featureChanged(record)
      }
    }
  }


  /**
   * Funkce pro kontrolu vykreslení poslední feature
   * Při vykreslení features nad očekávanou hranici se jedná o rerender popupu, ignoruji, nuluji
   * @param {*} featureID 
   */
  featureRender() {
    this.featuresRendered++;
    // console.log(this.featuresToRender, this.featuresRendered)
    if (this.featuresToRender === this.featuresRendered) {
      this.featuresToRender = 0;
      this.featuresRendered = 0;
      this.allFeaturesRendered()
    } else if (this.featuresToRender < this.featuresRendered) {
      this.featuresToRender = 0;
      this.featuresRendered = 0;
    }
  }

  /**
   * Called when all features in current bulk render (layer re-render) are rendered
   */
  allFeaturesRendered() {
    // if (this.loading) this.loading(false)
    if (this.RECORD_LAST_AREAS)
      this.recordLastAreas()
    if (this.RECORD_LAST_POSITIONS)
      this.recordLastPositions()
  }


  recordLastAreas() {
    let oldPositions = this.history.lastAreas;
    let newPositions = this.pointsInAreas();

    let featureIDs = [...new Set([...Object.keys(oldPositions), ...Object.keys(newPositions)])]

    this.history.pointsLeftAreas = {}
    this.history.pointsEnteredAreas = {}

    for (let featureID of featureIDs) {
      let oldpos = oldPositions[featureID];
      let newpos = newPositions[featureID];

      let positionsLeft = [];
      let positionsEntered = [];

      if (!oldpos && newpos) { // point entered some areas
        positionsEntered = newpos;
      } else if (oldpos && !newpos) { // point left all areas
        positionsLeft = oldpos;
      } else if (newpos && oldpos) { //requires closer look
        let allpos = [...new Set([...oldpos, ...newpos])]

        oldpos = oldpos.reduce((a, v) => ({ ...a, [v]: v }), {})
        newpos = newpos.reduce((a, v) => ({ ...a, [v]: v }), {})

        for (let pos of allpos) {
          if (!oldpos[pos] && newpos[pos]) // point entered specific area
            positionsEntered.push(pos)
          else if (oldpos[pos] && !newpos[pos]) // point left specific area
            positionsLeft.push(pos)
        }
      }

      if (positionsLeft.length > 0) {
        // console.log(`POINT ${featureID} LEFT `, positionsLeft)
        this.history.pointsLeftAreas[featureID] = positionsLeft;
      }
      if (positionsEntered.length > 0) {
        // console.log(`POINT ${featureID} ENTERED `, positionsEntered)
        this.history.pointsEnteredAreas[featureID] = positionsEntered;
      }

    }

    this.history.lastAreas = newPositions;
  }

  recordLastArea(featureID) {
    let oldpos = this.history.lastAreas?.[featureID];
    let newpos = this.pointInAreas(this.features[featureID])

    let positionsLeft = [];
    let positionsEntered = [];

    if (!oldpos && newpos) { // point entered some areas
      positionsEntered = newpos;
    } else if (oldpos && !newpos) { // point left all areas
      positionsLeft = oldpos;
    } else if (newpos && oldpos) { //requires closer look
      let allpos = [...new Set([...oldpos, ...newpos])]

      let oldposObj = oldpos.reduce((a, v) => ({ ...a, [v]: v }), {})
      let newposObj = newpos.reduce((a, v) => ({ ...a, [v]: v }), {})

      for (let pos of allpos) {
        if (!oldposObj[pos] && newposObj[pos]) // point entered specific area
          positionsEntered.push(pos)
        else if (oldposObj[pos] && !newposObj[pos]) // point left specific area
          positionsLeft.push(pos)
      }
    }

    if (positionsLeft.length > 0) {
      // console.log(`POINT ${featureID} LEFT `, positionsLeft)
      this.history.pointsLeftAreas[featureID] = positionsLeft;
    }
    if (positionsEntered.length > 0) {
      // console.log(`POINT ${featureID} ENTERED `, positionsEntered)
      this.history.pointsEnteredAreas[featureID] = positionsEntered;
    }

    this.history.lastAreas[featureID] = newpos;
    return newpos;
  }



  /**
   * Goes through every point on the map and saves each last position 
   */
  recordLastPositions() {
    let points = this.getPoints()
    for (let point of points) {
      this.recordLastPosition(point)
    }
  }


  /**
   * Saves last position of given point on the map
   * 
   * 
   * @param {*} point 
   */
  recordLastPosition(point) {

    if (!point?.featureID)
      point = this.features[point]

    if (point?.history)
      return null;

    let featureID = point.featureID;

    let poshis = this.history.lastPositions[featureID]

    if (!poshis)
      poshis = [];

    let lastpos = poshis?.[poshis.length - 1];

    if (!lastpos || (lastpos[0] !== point.coords[0] || lastpos[1] !== point.coords[1]))
      poshis.push([...point.coords, point.lastChangeTimestamp || new Date()]);

    if (poshis.length > this.HISTORY_SIZE)
      poshis.shift()

    this.history.lastPositions[featureID] = poshis;
    return poshis;
  }

  Map = (props) => <MapComponent controler={this} {...props} />;
  Layer = (props) => <Layer controler={this} {...props} />;
  Marker = (props) => <MarkerComponent controler={this} {...props} />;
  Polygon = (props) => <PolygonComponent controler={this} {...props} />;
  Circle = (props) => <CircleComponent controler={this} {...props} />;
  Indicator = (props) => <IndicatorComponent controler={this} {...props} />;
}


