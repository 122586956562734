import { Add, Remove, RemoveCircleOutlineRounded } from '@mui/icons-material';
import { Box, Button, Card, CardHeader, Checkbox, FormControlLabel, Grid, IconButton, Radio, TextField } from '@mui/material';
import { useCallback, useState } from 'react';
import ImageWithIcons from './ImageWithIcons';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { v4 as uuid } from "uuid";
import { useFieldArray } from 'react-hook-form';
import FormTextField from '../Form/TextField';
import FormCheckbox from '../Form/Checkbox';
import { useTranslation } from 'react-i18next';
import { useFileManager } from '../../../contexts/FileManagerContext';
import REST from '../../../utils/rest';

const getNewAnswer = () => ({ new_id: `new-answer-${uuid()}`, text: "", correct: 0 });

const AddImage = ({ onClick }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <IconButton onClick={onClick}><AddPhotoAlternateIcon fontSize='large' color="success" /></IconButton>
    </Box>
  );
}

const Item = ({ index, name, control, files, removeItem, addImage, removeImage, editImage, updateData, value, correct }) => {
  const { t } = useTranslation()

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card>
        <CardHeader
          subheader={t("question_editor.answer")}
          action={<>
            <IconButton title={t("general.remove")} onClick={() => removeItem(index)}>
              <RemoveCircleOutlineRounded color="error" />
            </IconButton>
          </>}
          sx={{ margin: 0, py: 1, px: 2 }}
        />
        <Box p={1} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Box minHeight={100} display="flex" justifyContent="center" alignItems="center">
            {files && files?.length > 0 ?
              <ImageWithIcons imageUrl={REST.URL("content/" + files[0].url)} removeImage={removeImage} editImage={editImage} />
              : <AddImage onClick={addImage} />}
          </Box>
          <Checkbox
            onChange={(e) => updateData("correct", e.target.checked ? 1 : 0)}
            checked={correct === 1 ? true : false}
          />
          <TextField
            variant="outlined"
            margin="normal"
            placeholder={t("question_editor.text")}
            onChange={(e) => {
              updateData("text", e.target.value);
            }}
            defaultValue={value}
          />
        </Box>
      </Card>
    </Grid >
  );
}

const ListImageAnswer = ({ name, control }) => {
  const fileManager = useFileManager();

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: `${name}.answers`,
    keyName: "rhf_id"
  });


  const { append: appendRemoved } = useFieldArray({
    control,
    name: `${name}.removed_answers`,
    keyName: "rhf_id"
  });

  const addItem = useCallback(() => {
    const newIndex = fields.length;
    append(getNewAnswer(newIndex));
  }, [append, fields]);

  const removeItem = useCallback((index) => {
    const item = fields[index];

    if (!item.new_id)
      appendRemoved(item);

    remove(index);
  }, [remove, appendRemoved, fields]);

  // Přidá obrázek
  const addImage = (index) => {
    fileManager.open({
      pickOne: true,
      callback: (data) => {
        const { EXTRA_FILE_ID, EXTRA_FILE, EXTRA_FILE_NAME, EXTRA_FILE_MIME_TYPE } = data[0];
        update(index, {
          ...fields[index], files: [{ id: EXTRA_FILE_ID, name: EXTRA_FILE_NAME, url: EXTRA_FILE, mime: EXTRA_FILE_MIME_TYPE }]
        })
      }
    });
  }

  // Odebere obrázek
  const removeImage = (index) => {
    update(index, { ...fields[index], files: [] })
  }

  const updateData = (index, propName, value) => {
    update(index, { ...fields[index], [propName]: value });
  }
  const { t } = useTranslation()
  return (
    <Box>
      <Grid container spacing={2}>
        {fields.map((i, index) =>
          <Item
            key={i.id || i.new_id}
            control={control}
            name={name}
            index={index}
            {...i}
            addImage={() => addImage(index)}
            removeItem={() => removeItem(index)}
            editImage={() => addImage(index)}
            removeImage={() => removeImage(index)}
            updateData={(propName, value) => updateData(index, propName, value)}
            value={fields[index].text}
          />
        )}
      </Grid>
      <Box px={6} mt={2}>
        <Button variant="contained" size="large" fullWidth startIcon={<Add />} onClick={addItem}>
          {t('question_editor.add_answer')}
        </Button>
      </Box>
    </Box>
  );
}

export default ListImageAnswer;
