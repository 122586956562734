import { useCallback, useEffect, useRef, useState } from 'react';
import {
  // Alert,
  Box,
  Button,
  CircularProgress,
  Popover,
  // FormHelperText,
  // Link,
  Stack,
  SvgIcon,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import { useAuth } from '@/hooks/useAuth';
import { Layout as AuthLayout } from '@/layouts/auth/layout';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import REST from '../utils/rest';
// import CreditCardIcon from '@heroicons/react/24/solid/CreditCardIcon';
// import SensorsRoundedIcon from '@mui/icons-material/SensorsRounded';
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import { login as logIn } from '../services/authService';
import { toastError, toastDismiss, toastSuccess, toastLoading } from '../utils/toasts';
import useEventListener from '../hooks/useEventListener';
import Loader from '../components/loader2';
import flags from '../data/languages.json'
import toast from 'react-hot-toast';
import { handleStorage } from '../utils/local-storage';
import { usePopover } from '@/hooks/usePopover';
import { GlobeAltIcon } from '@heroicons/react/24/solid';

const LanguagePopover = () => {

  const { t, i18n } = useTranslation()

  const accountPopover = usePopover();

  const [languages, setLanguages] = useState()
  const [chosenLanguage, setChosenLanguage] = useState(handleStorage(`get`, `default_language`))

  const getLanguages = useCallback(async () => {
    const [success, newdata] = await REST.GET(`languages`)
    if (!success) toastError()
    setLanguages(newdata?.Records)
  }, [])

  useEffect(() => {
    getLanguages()
  }, [])

  const sendLanguage = async (language, set_as_default, type) => await REST.POST(`ajax/set_language`, { language, set_as_default, type })

  const changeLanguage = async (l) => {

    setChosenLanguage(l?.LANGUAGE_ID)
    handleStorage(`set`, `default_language`, l?.LANGUAGE_ID)
    await sendLanguage(l?.LANGUAGE_ID, true, `ui`)//posílám jazyk do be


    toast(() => (<span>
      {t(`general.language`)} {t(`general.changed`)}
    </span>));

    i18n.changeLanguage(l?.LANGUAGE_ISO_CODE)

  }

  //let langiso = chosenLanguage ? languages?.find(x => x?.LANGUAGE_ID == chosenLanguage)?.LANGUAGE_ISO_CODE : `cs`
  //Vyplinit langiso podle jazyka z i18n
  let langiso = i18n.language

  console.log(`langiso`, langiso, chosenLanguage, languages)

  return <>
    <Button ref={accountPopover.anchorRef} sx={{ fontSize: "x-large", padding: 0, minWidth: 44, position: "absolute", top: 12, right: 12 }} onClick={accountPopover.handleOpen}>
      {flags[langiso] || langiso || <SvgIcon fontSize="small">
        <GlobeAltIcon />
      </SvgIcon>}
    </Button>
    <Popover
      anchorEl={accountPopover.anchorRef.current}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      onClose={accountPopover.handleClose}
      open={accountPopover.open}
      PaperProps={{ sx: { width: 200 } }}
    >
      <Box
        sx={{
          py: 1.5,
          px: 0
        }}>
        <Typography paddingLeft={2} variant="overline">
          {t(`general.language`)} {t(`general.of_interface`)}
        </Typography>
        <Box sx={{ px: 1 }}>
          {languages && languages?.filter(x => x?.UI_READY)?.map((l, id) => {
            return <Button variant={l?.LANGUAGE_ISO_CODE == langiso ? `outlined` : ``} sx={{ fontSize: "x-large", padding: 0, minWidth: 44, }} key={id} onClick={() => changeLanguage(l)}>
              {flags[l?.LANGUAGE_ISO_CODE] || l?.LANGUAGE_ISO_CODE}
            </Button>
          })}
        </Box>
      </Box>
    </Popover>
  </>
}


const Page = () => {
  //const router = useRouter();
  const { isAuthenticated, /* skip, */ login } = useAuth();
  const navigate = useNavigate();
  const [method, setMethod] = useState('rfidCard');
  const [submitted, setSubmitted] = useState(false);
  const cardData = useRef("");

  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const { t, i18n } = useTranslation();

  const usernameChanged = (e) => setUsername(e.target.value);
  const passwordChanged = (e) => setPassword(e.target.value);

  const handleEnter = (e) => {
    if (e.key === 'Enter') submit()
  };

  const handleMethodChange = useCallback(
    (event, value) => {
      setMethod(value);
    },
    []
  );

  // const [languages, setLanguages] = useState()
  // const [chosenLanguage, setChosenLanguage] = useState(handleStorage(`get`, `default_language`))

  // const getLanguages = useCallback(async () => {
  //   const [success, newdata] = await REST.GET(`languages`)
  //   if (!success) toastError()
  //   setLanguages(newdata?.Records)
  // }, [])

  // useEffect(() => {
  //   getLanguages()
  // }, [])

  // const sendLanguage = async (language, set_as_default, type) => await REST.POST(`ajax/set_language`, { language, set_as_default, type })

  // const changeLanguage = async (l) => {

  //   setChosenLanguage(l?.LANGUAGE_ID)
  //   handleStorage(`set`, `default_language`, l?.LANGUAGE_ID)
  //   await sendLanguage(l?.LANGUAGE_ID, true, `ui`)//posílám jazyk do be


  //   toast(() => (<span>
  //     {t(`general.language`)} {t(`general.changed`)}
  //   </span>));

  //   i18n.changeLanguage(l?.LANGUAGE_ISO_CODE)

  // }
  let inputTimeout;
  useEffect(() => {

    function handleCardReaderInput(event) {
      console.log('RFID-handleKeyDown', cardData.current, event)
      // Timeout pro odchytávání karty
      const TIMEOUT_DURATION = 500;

      // Odchytávám shift a semicolon
      if ([186, 16].includes(event.keyCode))
        return;

      if (event.key === 'Enter') {
        console.log('RFID-submit', cardData.current)
        submitRFID(cardData.current);
        cardData.current = "";
        clearTimeout(inputTimeout);
        return;
      }

      var charCode = event.which || event.keyCode;
      var charStr = String.fromCharCode(charCode);
      cardData.current = cardData.current + charStr;

      // Timeout pro odchytávání karty
      clearTimeout(inputTimeout);
      inputTimeout = setTimeout(() => {
        //Odchycené znaky se resetují, pokud nebyla karta přečtena dostatečně rychle
        console.log('RFID-reset')
        cardData.current = "";
      }, TIMEOUT_DURATION);

    }

    if (method === "rfidCard") {
      document.addEventListener('keydown', handleCardReaderInput);
    }
    return () => {
      // tady listener odregistrovat
      document.removeEventListener('keydown', handleCardReaderInput);

    }
  }, [method]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  /*   const handleSkip = useCallback(
      async () => {
        const user = await logIn("jan.novak@pascom.cz", "Heslo1234");
        if (user?.Result === "ERROR") {
          toastError("Přihlášení se nepodařilo.");
          return;
        }
  
        if (user) {
          login(user)
          navigate('/');
        } else {
          toastError("Přihlášení se nepodařilo.");
        }
      },
      [skip, navigate]
    );
   */

  const psswdReset = useCallback(
    async (event) => {
      const email = username;
      const type = 'reset-password';
      const values = { email, type }
      event.preventDefault();
      const loading = toastLoading()
      const [success, result] = await REST.POST(`ajax/change_user_details`, values)
      toastDismiss(loading)
      if (!success)
        return toastError(t(result?.message))
      toastSuccess(t(`account.details_changed`))
    },
  );

  const submit = async (e) => {
    e?.preventDefault();
    if (submitted) return false;
    setSubmitted(true);

    console.log(`submit.......`, username, password)

    let errorMsg = false;

    if (!username && !password)
      errorMsg = `Zadejte uživatelské jméno a heslo`;
    else if (!username)
      errorMsg = `Zadejte uživatelské jméno`;
    else if (!password)
      errorMsg = `Zadejte heslo`;

    if (errorMsg) {
      setSubmitted(false);
      return toastError(errorMsg);
    }

    const user = await logIn(username, password)
    setSubmitted(false);

    if (user) {
      login(user)
      navigate('/');
    } else {
      toastError("Přihlášení se nepodařilo.")
    }
  }


  useEventListener(`rfid`, ({ detail }) => {
    submitRFID(detail)
  })

  const submitRFID = async (rfid) => {
    const user = await logIn(null, null, rfid)
    if (user) {
      login(user)
      navigate('/');
    } else {
      //Pokud RFID nebylo ve správném formátu (osm znaků), pak hlášku ani nezobrazuji
      if (rfid.length === 8)
      toastError("Přihlášení se nepodařilo.")
    }
  }

  return (
    <>
      <Loader />
      <Box
        sx={{
          backgroundColor: 'background.paper',
          flex: '1 1 auto',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            maxWidth: 550,
            px: 3,
            py: '100px',
            width: '100%'
          }}
        >
          <Box>
            <LanguagePopover />
          </Box>
          <div>
            <Stack
              spacing={1}
              sx={{ mb: 3 }}
            >
              <Typography variant="h4">
                {t("loginPage.form.login")}
              </Typography>
              {/*<Typography
                color="text.secondary"
                variant="body2"
              >
                Nemáte ještě účet?
                &nbsp;
                <Link
                  component={NavLink}
                  href="/auth/register"
                  underline="hover"
                  variant="subtitle2"
                >
                  Registrujte se
                </Link>
            </Typography>*/}
            </Stack>
            <Tabs
              onChange={handleMethodChange}
              sx={{ mb: 3 }}
              value={method}
            >
              <Tab
                label={t("loginPage.form.username")}
                value="username"
              />
              <Tab
                label={t("loginPage.form.card")}
                value="rfidCard"
              />
            </Tabs>
            {method === 'username' && (
              <form
                // noValidate
                onSubmit={submit}
              >
                <Stack spacing={3}>
                  <TextField
                    //error={false}
                    fullWidth
                    //helperText={formik.touched.email && formik.errors.email}
                    label={t("loginPage.form.username")}
                    name="username"
                    onBlur={usernameChanged}
                    onChange={usernameChanged}
                    onKeyPress={handleEnter}
                    type="text"
                  //value={formik.values.email}
                  />
                  <TextField
                    //error={!!(formik.touched.password && formik.errors.password)}
                    fullWidth
                    //helperText={formik.touched.password && formik.errors.password}
                    label={t("loginPage.form.password")}
                    name="password"
                    onBlur={passwordChanged}
                    onChange={passwordChanged}
                    onKeyPress={handleEnter}
                    type="password"
                  //value={formik.values.password}
                  />
                </Stack>
                {/* <FormHelperText sx={{ mt: 1 }}>
                  {t("loginPage.demo.skipOption")}
                </FormHelperText> */}
                {false && (
                  <Typography
                    color="error"
                    sx={{ mt: 3 }}
                    variant="body2"
                  >
                    {/* error */}
                  </Typography>
                )}
                {<Button
                  size="small"
                  sx={{ ml: '70%', mt: 1 }}
                  onClick={psswdReset}>
                  {t("loginPage.resetPassword")}
                </Button>}
                <Button
                  fullWidth
                  size="large"
                  sx={{ mt: 3 }}
                  type="submit"
                  variant="contained"
                  startIcon={submitted ? <CircularProgress size={20} /> : null}
                  disabled={submitted}
                >
                  {t("loginPage.continue")}
                </Button>
                {/* <Button
                  fullWidth
                  size="large"
                  sx={{ mt: 3 }}
                  onClick={handleSkip}
                >
                  {t("loginPage.demo.skipLogin")}
                </Button> */}
                {/* <Alert
                  color="primary"
                  severity="info"
                  sx={{ mt: 3 }}
                >
                  <div>
                    <Trans i18nKey={"loginPage.demo.loginInfo"}>
                      Pro demo přihlášení použij <b>jan.novak@pascom.cz</b> a heslo <b>Heslo1234</b>
                    </Trans>
                  </div>
                </Alert> */}
              </form>
            )}
            {method === 'rfidCard' && (
              <Box mt="2rem">
                <Box display="flex" justifyContent="center">
                  <CreditCardRoundedIcon sx={{ fontSize: 200 }} color="primary" />
                </Box>
                <Typography variant="h5" textAlign="center">
                  {t("loginPage.form.attachCard")}
                </Typography>
              </Box>
            )}
          </div>
          {/* <Box display={"flex"} justifyContent={"center"} sx={{ px: 1 }}>
            {languages && languages?.filter(x => x?.UI_READY)?.map((l, id) => {
              return <Button variant={l?.LANGUAGE_ID == chosenLanguage ? `outlined` : ``} sx={{ fontSize: "x-large", padding: 0, minWidth: 44, }} key={id} onClick={() => changeLanguage(l)}>
                {flags[l?.LANGUAGE_ISO_CODE] || l?.LANGUAGE_ISO_CODE}
              </Button>
            })}
          </Box> */}
        </Box>
      </Box>
    </>
  );
};

Page.getLayout = (page) => (
  <AuthLayout>
    {page}
  </AuthLayout>
);

export default Page;
