import PropTypes from "prop-types"
import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, styled, Checkbox, Grid, Box, ListItemButton, alpha, SvgIcon } from '@mui/material';
import REST from "../../../utils/rest";
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import RadioButtonCheckedRoundedIcon from '@mui/icons-material/RadioButtonCheckedRounded';
import { useFullscreen } from "../../../contexts/FullscreenContext";
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import { Icon } from "../../../components/icon";
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';

// Vzhledové úpravy pro ListItem
const StyledListItem = styled(ListItemButton, {
  shouldForwardProp: (prop) =>
    prop !== "correct" && prop !== "isSelected" && prop !== "isAnswered"
})(({ theme, isSelected, isAnswered, correct }) => {

  const color = correct ? "success" : "error"

  return ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    margin: '8px',
    backgroundColor: '#f5f5f5',
    '&:hover': {
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
    },

    ...(isAnswered && correct && {
      backgroundColor: alpha(theme.palette[color].light, 0.5),
      color: theme.palette[color].dark,
      [".MuiTypography-root"]: {
        fontWeight: "bold"
      },
    }),
    ...(isAnswered && (isSelected && correct === 0) && {
      backgroundColor: alpha(theme.palette[color].main, 0.5),
      color: theme.palette[color].darkest,
      [".MuiTypography-root"]: {
        fontWeight: "bold"
      },
    }),
    padding: '10px 20px',
    marginBottom: '8px',
    transition: 'background-color 0.3s ease', // přidává plynulý přechod mezi barvami
  })
});

const FullscreenIcon = styled(Box)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: alpha(theme.palette.common.white, 0.7),
  //Drop shadow
  boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.40)',
  borderRadius: '50%',
  padding: '5px',
  width: '30px',
  height: '30px',
  //To the right
  right: '10px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.2),
  },
}));

const AnswerSelector = ({ id, answers, setAnswer, isAnswered, isSuccess, isMultiple }) => {
  const defaultSelected = isAnswered ? answers.filter(i => i.selected).map(i => i.id) : [];
  const [selected, setSelected] = useState(defaultSelected);
  const UncheckedIcon = isMultiple ? CheckBoxOutlineBlankRoundedIcon : RadioButtonUncheckedRoundedIcon;
  const CheckedIcon = isMultiple ? CheckBoxRoundedIcon : RadioButtonCheckedRoundedIcon;

  const fullscreen = useFullscreen()

  const openItem = (rec) => {
    let tag = `img`;
    console.log(`open item`, { rec, tag })
    fullscreen.open({
      [tag]: REST.URL(`content/${rec}`)
    })
  }

  useEffect(() => {
    setAnswer(selected);
  }, [selected, setAnswer, isMultiple]);

  const handleChange = ({ id }) => {
    if (!isMultiple)
      return setSelected([id]);

    setSelected(prev => {
      if (prev.includes(id)) {
        return prev.filter(i => i !== id);
      } else {
        return [...prev, id];
      }
    });
  }

  return (
    <Grid container spacing={2} justifyContent="center">
      {answers.map(({ id, description, files, correct }) => {
        const text = description;
        const { url, name } = files?.[0] || {};
        const isSelected = selected.includes(id);
  
        const isCorrect = isSuccess ? (correct ? 1 : undefined) : correct;
  
        return (
          <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
            <StyledListItem
              onClick={() => !isAnswered && handleChange({ id })}
              isSelected={isSelected}
              correct={correct}
              isAnswered={isAnswered}
            >
              {url && (
                <Box position="relative">
                  <Box
                    component="img"
                    src={REST.URL(`content/${url}`)}
                    alt={name}
                    maxWidth="100%"
                    borderRadius={2}
                    border={1}
                    borderColor="InactiveBorder"
                  />
                  <FullscreenIcon
                    onClick={(event) => {
                      event.stopPropagation();
                      openItem(url);
                    }}
                    style={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                    }}
                  >
                    <MagnifyingGlassIcon />
                  </FullscreenIcon>
                </Box>
              )}
              <ListItemText primary={text} />
              {isSelected ? <CheckedIcon color={isAnswered && isCorrect ? "success" : "primary"} /> : <UncheckedIcon />}
            </StyledListItem>
          </Grid>
        );
      })}
    </Grid>
  );
};

AnswerSelector.propTypes = {
  answers: PropTypes.array,
  id: PropTypes.any
}

export default AnswerSelector;