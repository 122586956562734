import { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent
} from '@mui/material';

import REST from '../utils/rest';
import { toastDismiss, toastError, toastLoading, toastSuccess } from '../utils/toasts';
import { useAuth } from '../contexts/AuthContext';
import { useTranslation } from 'react-i18next';

export const RequestEmailDialog = () => {

  const { t } = useTranslation()
  const { currentUser, requestEmail, login, setRequestEmail } = useAuth();
  const [values, setValues] = useState({
    // firstName: currentUser.UserFirstname,
    // lastName: currentUser.UserSurname,
    email: `@`,
  });
  const [blockSubmit, setBlockSubmit] = useState(false)

  const handleChange = useCallback(
    (event) => {
      setValues((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value
      }));
    },
    []
  );

  const handleClose = useCallback(
    async () => {
      setRequestEmail(false);
      await REST.POST(`ajax/change_user_details`, { notifOff: true });
    }, []
  );

  const handleSubmit = useCallback(
    async (event) => {
      setBlockSubmit(true)
      event.preventDefault();

      if (values.email === '@') {
        handleClose();
        return;
      }

      const loading = toastLoading()
      const [success, result] = await REST.POST(`ajax/change_user_details`, values)
      toastDismiss(loading)
      setBlockSubmit(false)

      if (!success)
        return toastError(t(result?.message))

      login(result)

      toastSuccess(t(`account.details_changed`))

      setRequestEmail(false)

    }, [values, handleClose, t, login, setRequestEmail]
  );

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
    >
      <Dialog open={requestEmail} onClose={handleClose}>
        <DialogTitle>{t("request_email.title")}</DialogTitle>
        <DialogTitle variant="subtitle1">{t("request_email.subtitle")}</DialogTitle>
        <DialogContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid
              container
              spacing={3}
            >
              {/* <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  // helperText="Please specify the first name"
                  label={t(`U_NAME`)}
                  name="firstName"
                  onChange={handleChange}
                  // required
                  value={values.firstName}
                  disabled
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label={t(`SURNAME`)}
                  name="lastName"
                  onChange={handleChange}
                  // required
                  value={values.lastName}
                  disabled
                />
              </Grid> */}
              <Grid
                xs={12}
              // md={6}
              ><TextField
                  fullWidth
                  label={t(`EMAIL`)}
                  name="email"
                  onChange={handleChange}
                  // required
                  value={values?.email}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={blockSubmit} variant="outlined" onClick={handleClose}>{t(`general.cancel`)}</Button>
          <Button disabled={blockSubmit} variant="contained" type='submit' onClick={handleSubmit}>{t(`general.accept`)}</Button>
        </DialogActions>
      </Dialog>
    </form >
  );
};