import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { t } from 'i18next';

const Legend = ({ data }) => {
  const [isVisible, setIsVisible] = useState(true);

  const toggleLegend = () => {
    setIsVisible(!isVisible);
  };

  const getEmoji = (rank) => {
    switch (rank) {
      case 1:
        return '🥇';
      case 2:
        return '🥈';
      case 3:
        return '🥉';
      default:
        return '';
    }
  };

  const filledData = Array.from({ length: 3 }, (_, index) => {
    return data[index] || { RANK: index + 1, USER_NAME: '-', USER_SURNAME: '-' };
  });

  return (
    <Box sx={{ position: 'absolute', top: 15, right: 80, zIndex: 10 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <Button variant="contained" onClick={toggleLegend} sx={{ minWidth: 150 }}>
          {isVisible ? t("map.hide_winners") : t("map.show_winners")}
        </Button>
        {isVisible && (
          <Box sx={{ backgroundColor: 'white', padding: 2, marginTop: 1, borderRadius: 1, boxShadow: 3 }}>
            <table>
              <thead>
              </thead>
              <tbody>
                {filledData.map((item) => (
                  <tr key={item.RANK}>
                    <td>{getEmoji(item.RANK)}</td>
                    <td>{item.USER_NAME}</td>
                    <td>{item.USER_SURNAME}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Legend;