import { Box, Container, Stack, Typography, Unstable_Grid2 as Grid, Button, SvgIcon } from '@mui/material';
import { Layout as DashboardLayout } from '@/layouts/dashboard/layout';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { AccountProfile } from '../sections/account/account-profile';
import { AccountProfileDetails } from '../sections/account/account-profile-details';
import { useCallback, useEffect, useState } from 'react';
import REST from '../utils/rest';
import { toastDismiss, toastError, toastLoading, toastSuccess } from '../utils/toasts';
import toast from 'react-hot-toast';
import { logOut } from '../services/authService';
import { AccountLanguageEditing } from '../sections/account/account-language-editing';
import { AccountPasswordEditing } from '../sections/account/account-password-editing';
import { ArrowLeftCircleIcon } from '@heroicons/react/24/solid';

const Page = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation()
  const { user, login, logout } = useAuth();
  const [languages, setLanguages] = useState()

  const getLanguages = useCallback(async () => {
    const [success, newdata] = await REST.GET(`languages`)
    if (!success) toastError()
    setLanguages(newdata?.Records)
  }, [])

  useEffect(() => {
    getLanguages()
  }, [])

  const sendLanguage = async (language, set_as_default, type) => await REST.POST(`ajax/set_language`, { language, set_as_default, type })

  const changeLanguage = async (type, l, set_as_default = true) => {

    console.log(`language change initiated`, l, set_as_default, user)

    await sendLanguage(l?.LANGUAGE_ID, set_as_default, type)//posílám jazyk do be

    //nabízím nastavit jako výchozí
    // if (!set_as_default && l?.LANGUAGE_ID != currentUser.UserLangID)
    toast((ts) => (<span>
      {t(`general.language`)} {t(`general.changed`)}
      {/* <Button onClick={async () => {
          await changeLanguage(l, true)
          toast.dismiss(ts.id)
        }}>
          {t(`general.set_as_default`)}
        </Button> */}
    </span>));

    if (set_as_default) {
      console.log(`changeLanguage......`, user)
      user.UserLangID = l?.LANGUAGE_ID;
      user.UserLangISOCode = l?.LANGUAGE_ISO_CODE;
      login(user)
    } else
      i18n.changeLanguage(l?.LANGUAGE_ISO_CODE)

    // i18n.changeLanguage(l?.LANGUAGE_ISO_CODE)

    //pokud jsem změnil jazyk uložím si do paměti že ho chci po reloadu nabídnout jako defaultní
    // if (!set_as_default && l?.LANGUAGE_ID != currentUser.UserLangID) {
    //   handleStorage(`set`, `ask_to_set_default_after_reload`, l)
    window.location.reload()
    // }
  }


  const handleSignOut = useCallback(
    async () => {
      const result = await logOut()
      if (result) {
        logout();
        navigate(`/login`)
        toast.success(t("general.logout_msg"), { duration: 4000, position: "top-center" });
      }
      else toastError(t(`auth.logout_failed`))
    },
    [logout, navigate]
  );

  const passProps = { t, user, navigate, login, logout, changeLanguage, handleSignOut, languages }

  const passwordSubmit = useCallback(async (values) => {
    values.hash = user?.PASSWORD_CHANGE_HASH;
    const loading = toastLoading()
    const [success, result] = await REST.POST(`change_password`, values)
    toastDismiss(loading)

    if (!success) {
      return toastError(t(result?.message))
    }

    toastSuccess(t(`account.password_changed`))

    // return true

    return success;
  }, [user])

  const [leftPosition, setLeftPosition] = useState('18%');
  const goToMainPage = () => {
    window.location.href = "/";
  };

  useEffect(() => {
    const updatePosition = () => {
      if (window.innerWidth <= 1200) {
        setLeftPosition('10px');
      } else {
        setLeftPosition('295px');
      }
    };

    updatePosition(); // Set initial position
    window.addEventListener('resize', updatePosition); // Update position on resize

    return () => window.removeEventListener('resize', updatePosition); // Cleanup on unmount
  }, []);

  return <>
    <SvgIcon
      onClick={goToMainPage}
      style={{
        position: 'absolute',
        top: '70px',
        left: leftPosition,
        cursor: 'pointer',
        fontSize: '50px',
        zIndex: 1000 // Ensure it is on top of other elements
      }}
    ><ArrowLeftCircleIcon /></SvgIcon>
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8
      }}
    >
      <Container >
        <Stack spacing={3}>
          {/* <div> */}
            <Typography variant="h2">{t(`menu.account`)}</Typography>
          {/* </div> */}
          <div>
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={6}
                lg={4}
              >
                <AccountProfile {...passProps} />
              </Grid>
              <Grid
                xs={12}
                md={6}
                lg={8}
              >
                <AccountProfileDetails {...passProps} />
              </Grid>
              <Grid
                xs={12}
                md={6}
                lg={6}
              >
                <AccountLanguageEditing {...passProps} type={`ui`} />
              </Grid>
              <Grid
                xs={12}
                md={6}
                lg={6}
              >
                <AccountLanguageEditing {...passProps} type={`test`} />
              </Grid>
              <Grid
                xs={12}
                md={6}
                lg={8}
              >
                {/* <Button
                  variant='contained'
                  size='large'
                  onClick={() => navigate(`/change_password/testhash`)}
                >{t(`account.change_password`)}</Button> */}
                <AccountPasswordEditing {...passProps} onSubmit={passwordSubmit} />
              </Grid>
            </Grid>
          </div>
        </Stack>
      </Container>
    </Box>
  </>
}

Page.getLayout = (page) => (
  <DashboardLayout>
    {page}
  </DashboardLayout>
);

export default Page;
